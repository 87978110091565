import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { ProductsComponent } from './products.component';
import { HomeComponent } from './home.component';
import { AboutusComponent } from './aboutus.component';
import { ContactusComponent } from './contactus.component';
import { SkincareProductComponent } from './skincareproduct.component';
import { HaircareProductComponent } from './haircareproduct.component';
import { HealthcareProductComponent } from './healthcateproduct.component';
import { CleanserComponent } from './cleanser.component';
import { BBcreamComponent } from './bbcream.component';
import { SleepingmaskComponent } from './sleepingmask.component';
import { PowderComponent } from './powder.component';
import { EmulsionComponent } from './emulsion.component';
import { FacialMaskComponent } from './facialmask.component';
import { EssenceComponent } from './essence.component';
import { TonerComponent } from './toner.component';
import { CleansingWaterComponent } from './cleansingwater.component';
import { SuncreamComponent } from './suncream.component';
import { BodyLotionComponent } from './bodylotion.component';
import { LoseweightsetComponent } from './loseweightset.component';
import { JellyComponent } from './jelly.component';
import { CollagenComponent } from './collagen.component';
import { ConditionerComponent } from './conditioner.component';
import { GelComponent } from './gel.component';
import { ShampooComponent } from './shampoo.component';
import { SoapComponent } from './soap.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InternationalizationModule } from './internationliztion.module';
import { ChangeDetection } from './shared/changedetection';

import {CosmeticproductComponent} from "./cosmeticproduct.component";

// carousel
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { FacebookModule } from 'ngx-facebook';
import { MaleReplacementShakeComponent } from './malereplacementshake.component';
import { ToothpasteComponent } from './toothpaste.component';

//new skincare
import { NewCleanserComponent } from './newcleanser.component';
import { NightCreamComponent } from './nightcream.component';
import { CushionComponent } from './cushion.component';
import { DayCreamComponent } from './daycream.component';
import { NewEssenceComponent } from './newessence.component';
import { PowderPactComponent } from './powderpact.component';
import { NewTonerComponent } from './newtoner.component';
import {LipstickComponent} from "./lipstick.component";
import {EyebrowpencilComponent} from "./eyebrowpencil.component";
import {EyeshadowComponent} from "./eyeshadow.component";
import {BlusherComponent} from "./blusher.component";
import {EyelinerComponent} from "./eyeliner.component";
import {MascaraComponent} from "./mascara.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/locales/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ProductsComponent,
    HomeComponent,
    AboutusComponent,
    ContactusComponent,
    SkincareProductComponent,
    HealthcareProductComponent,
    HaircareProductComponent,
    CleanserComponent,
    BBcreamComponent,
    SleepingmaskComponent,
    PowderComponent,
    EmulsionComponent,
    FacialMaskComponent,
    EssenceComponent,
    TonerComponent,
    CleansingWaterComponent,
    SuncreamComponent,
    BodyLotionComponent,
    LoseweightsetComponent,
    JellyComponent,
    CollagenComponent,
    ConditionerComponent,
    GelComponent,
    ShampooComponent,
    SoapComponent,
    MaleReplacementShakeComponent,
    ToothpasteComponent,
    NewCleanserComponent,
    NightCreamComponent,
    CushionComponent,
    DayCreamComponent,
    NewEssenceComponent,
    PowderPactComponent,
    NewTonerComponent,
    CosmeticproductComponent,
    LipstickComponent,
    EyebrowpencilComponent,
    EyeshadowComponent,
    BlusherComponent,
    EyelinerComponent,
    MascaraComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    InternationalizationModule.forRoot({ locale_id: 'myn' }),
    SlickCarouselModule,
    FacebookModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [ChangeDetection],
  bootstrap: [AppComponent],
})
export class AppModule {}
