<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <div style="margin-top: 10%;margin-bottom: 5%;">
        <label class="prod-link" style="margin-left: 10%;">
            <a class="prod-link" routerLink="/products">Product</a> /<a class="prod-link" routerLink="/haircareproduct">Haircare</a>/ Shampoo
        </label>
    </div>
    <div class="detail-div">
        <div class="prod-detail-grid">
            <div class="lf-half-circle">
                <button class="next-btn" style="margin-left: 11vw !important;" [routerLink]="['/conditioner']">PREV</button>
            </div>
            <div class="prod-detail-cols">
                <img class="prod-detail-img-sham" src="/assets/haircare/shampoo.png" />
            </div>
            <div class="prod-detail-title">
                <label class="prod-detail-name">SHAMPOO</label><br><br>
                <label class="prod-detail-info" style="font-size:16px">Enjoy Life, Frangrance Lasting and Emission Charm</label><br><br>
                <label class="prod-detail-info" style="font-size:16px">Net: 500ml</label><br><br>
            </div>
        </div>
        <div class="rg-half-circle">
            <button class="next-btn" [routerLink]="['/gel']">NEXT</button>
        </div>
    </div>
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.productdetails' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="margin-top: 0% !important;text-align: center;">
    <img *ngIf="lang == 'myn'" style="width: 90%" src="/assets/014_shampoodetail/corecomponents.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/014_shampoodetail/corecomponents_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/014_shampoodetail/tip1.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/014_shampoodetail/tip1_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/014_shampoodetail/tip2.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/014_shampoodetail/tip2_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/014_shampoodetail/tip3.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/014_shampoodetail/tip3_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/014_shampoodetail/tip4.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/014_shampoodetail/tip4_en.png" />
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.certis' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img style="width:100%" src="/assets/014_shampoodetail/certificate.png" />
</div>

<style>
    .prod-detail-img-sham
        {
            width: 25%; 
            padding-left: 30%;
            margin-bottom: 90px;

        }
    @media only screen and (max-width: 600px) {
        .prod-detail-img-sham
        {   
            margin-top: 5%;
            width: 40%;
            padding-left: 35%;
            margin-bottom: 20px;
        }
    }
</style>