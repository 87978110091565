import { Component } from '@angular/core';

@Component({
  selector: 'skincareproduct',
  templateUrl: './skincareproduct.component.html',
})
export class SkincareProductComponent {
  constructor() { }

  ngOnInit() {
    
  }
}
