<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <div style="margin-top: 10%;margin-bottom: 5%;">
        <label class="prod-link" style="margin-left: 10%;">
            <a class="prod-link" routerLink="/products">Product</a> /<a class="prod-link" routerLink="/haircareproduct">Haircare</a>/ Whitening Handmade Soap
        </label>
    </div>
    <div class="detail-div">
        <div class="prod-detail-grid">
            <div class="prod-detail-cols">
                <img class="prod-detail-img-soap" src="/assets/haircare/soap.png" />
            </div>
            <div class="prod-detail-title">
                <label class="prod-detail-name">WHITENING HANDMADE SOAP</label><br><br>
                <label class="prod-detail-info" style="font-size:16px">Clear skin, improve calm tender and elastic</label><br><br>
                <label class="prod-detail-info" style="font-size:16px">Net: 100g</label><br><br>
            </div>
        </div>
        <div class="rg-half-circle">
            <button class="next-btn" [routerLink]="['/conditioner']">NEXT</button>
        </div>
    </div>
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.productdetails' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width:100%" src="/assets/012_soapdetail/corecomponents.png" />
    <img *ngIf="lang == 'en'" style="width:100%" src="/assets/012_soapdetail/corecomponents_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/012_soapdetail/tip1.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/012_soapdetail/tip1_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width:100%" src="/assets/012_soapdetail/tip2.png" />
    <img *ngIf="lang == 'en'" style="width:100%" src="/assets/012_soapdetail/tip2_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/012_soapdetail/tip3.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/012_soapdetail/tip3_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/012_soapdetail/tip4.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/012_soapdetail/tip4_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/012_soapdetail/tip5.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/012_soapdetail/tip5_en.png" />
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.certis' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;text-align: center;">
    <img style="width:80%;padding:20px 0px;" src="/assets/012_soapdetail/certificate.png" />
</div>


<style>
    .prod-detail-img-soap
        {
            width: 70%;
            margin-left: 20%;
            margin-bottom: 20%;

        }
    @media only screen and (max-width: 600px) {
        .prod-detail-img-soap
        {
            width: 75%;
            padding-left: 5%;
            margin-bottom: 10%;
        }
    }
</style>