import { Component } from '@angular/core';

@Component({
  selector: 'cosmeticproduct',
  templateUrl: './cosmeticproduct.component.html',
})
export class CosmeticproductComponent {
  constructor() { }

  ngOnInit() {

  }
}
