<!-- Skincare -->
<div class="col-xs-12 overall-container">
  <div style="margin-top: 2%; margin-bottom: 1%">
    <label class="prod-link" style="margin-left: 15%">
      <a class="prod-link" routerLink="/products">Product</a>/ Skincare
    </label>
  </div>
  <div class="about-header" style="margin-bottom: 3%">
    <label class="title">SKINCARE PRODUCTS</label>
  </div>

  <div class="container">
    <div class="row">
      <!-- <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 95%; padding-top: 20px; padding-left: 12px"
            src="/assets/skincare/cleanser.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 28px"
            >Cleanser</label
          >
          <label class="product-label"
            >Soft Shining and Moisturizing Amino Acid</label
          >
          <label class="product-label">Facial Cleanser</label>
          <button class="learn-btn" [routerLink]="['/cleanser']">
            Learn More
          </button>
        </div>
      </div> -->
      <!-- <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 60%; padding-top: 38px; padding-left: 61px"
            src="/assets/skincare/BBCream.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 32px"
            >BB Cream</label
          >
          <label class="product-label">Light and Concealer</label>
          <button
            class="learn-btn"
            style="margin-top: 45px !important"
            [routerLink]="['/bbcream']"
          >
            Learn More
          </button>
        </div>
      </div> -->
      <!-- <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 95%; padding-top: 20px; padding-left: 12px"
            src="/assets/skincare/sleepingmask.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 30px"
            >Sleeping Mask</label
          >
          <label class="product-label"
            >Peptide Repair Facial Sleeping Mask</label
          >
          <button
            class="learn-btn"
            style="margin-top: 45px !important"
            [routerLink]="['/sleepingmask']"
          >
            Learn More
          </button>
        </div>
      </div> -->
      <!-- <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 95%; padding-top: 66px; padding-left: 12px"
            src="/assets/skincare/powder.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 40px"
            >Setting Powder</label
          >
          <label class="product-label">Magic Featherlight Setting Powder</label>
          <button
            style="margin-top: 35px"
            class="learn-btn"
            [routerLink]="['/powder']"
          >
            Learn More
          </button>
        </div>
      </div> -->
      <!-- <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 54%; padding-top: 39px; padding-left: 70px"
            src="/assets/skincare/emulsion.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 28px"
            >Emulsion</label
          >
          <label class="product-label"
            >Moisturizing and Light Protection Emulsion</label
          >
          <button class="learn-btn" [routerLink]="['/emulsion']">
            Learn More
          </button>
        </div>
      </div> -->
      <!-- <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 95%; padding-top: 70px; padding-left: 12px"
            src="/assets/skincare/facialmask.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 40px"
            >Facial Mask</label
          >
          <label class="product-label"
            >Sooth Tender and Shining Facial Mask</label
          >
          <button
            style="margin-top: 35px"
            class="learn-btn"
            [routerLink]="['/facialmask']"
          >
            Learn More
          </button>
        </div>
      </div> -->
      <!-- <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 60%; padding-top: 35px; padding-left: 58px"
            src="/assets/skincare/essence.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 30px"
            >Essence</label
          >
          <label class="product-label"
            >Skin permeation, Multi-Effect Repair, Shining Essence</label
          >
          <button class="learn-btn" [routerLink]="['/essence']">
            Learn More
          </button>
        </div>
      </div> -->
      <!-- <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 55%; padding-top: 38px; padding-left: 66px"
            src="/assets/skincare/toner.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 30px"
            >Toner</label
          >
          <label class="product-label"
            >Luxurious, Contain Treasure Activate, relieve toner</label
          >
          <button class="learn-btn" [routerLink]="['/toner']">
            Learn More
          </button>
        </div>
      </div> -->
      <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 90%; padding-top: 38px; padding-left: 20px"
            src="/assets/skincare/cleansingwater.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 30px"
            >Cleansing Water</label
          >
          <label class="product-label"
            >99% mild cleansing with just 1 cotton pad</label
          >
          <button class="learn-btn" [routerLink]="['/cleansingwater']">
            Learn More
          </button>
        </div>
      </div>
      <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 90%; padding-top: 30px; padding-left: 20px"
            src="/assets/skincare/suncream.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 30px"
            >Sun Cream</label
          >
          <label class="product-label"
            >The SPF 50+/PA+++ sunscreen can keep the skin youthful and
            healthy.</label
          >
          <button class="learn-btn" [routerLink]="['/suncream']">
            Learn More
          </button>
        </div>
      </div>
      <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 90%; padding-top: 30px; padding-left: 20px"
            src="/assets/skincare/bodylotion.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 30px"
            >Body Lotion</label
          >
          <label class="product-label"
            >a daily-use body lotion that moisturizes the skin and doesn't leave
            a sticky residue</label
          >
          <button class="learn-btn" [routerLink]="['/bodylotion']">
            Learn More
          </button>
        </div>
      </div>
      <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 95%; padding-left: 12px"
            src="/assets/skincare/newcleanser.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 2px"
            >Cleanser</label
          >
          <label class="product-label"
            >Daily Peeling & Washing 2 in 1 cleansing foam</label
          >
          <button class="learn-btn" [routerLink]="['/newcleanser']">
            Learn More
          </button>
        </div>
      </div>

      <div class="col-4">
        <div class="prod-body py-5">
          <img
            style="width: 95%; padding-top: 50px; padding-left: 5px"
            src="/assets/skincare/nightcream.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 47px"
            >Night Cream</label
          >
          <label class="product-label mb-5"
            >Recovering Tired Skin Over Night</label
          >
          <!-- <label class="product-label">Cleansing Foam</label> -->
          <button class="learn-btn" [routerLink]="['/nightcream']">
            Learn More
          </button>
        </div>
      </div>

      <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 95%; padding-top: 45px; padding-left: 10px"
            src="/assets/skincare/cushion.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 40px"
            >Cushion</label
          >
          <label class="product-label mb-5"
            >To prevent the sunlight and provides extra layer for the skin</label
          >

          <button class="learn-btn" [routerLink]="['/cushion']">
            Learn More
          </button>
        </div>
      </div>

      <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 95%; padding-top: 45px; padding-left: 8px"
            src="/assets/skincare/daycream.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 35px"
            >Day Cream</label
          >
          <label class="product-label"
            >Smoothens,Softens and Refreshes The Fical Skin</label
          >
          <!-- <label class="product-label">Cleansing Foam</label> -->
          <button class="learn-btn" [routerLink]="['/daycream']">
            Learn More
          </button>
        </div>
      </div>

      <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 95%; padding-top: 45px; padding-left: 5px"
            src="/assets/skincare/newessence.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 50px"
            >Essence</label
          >
          <label class="product-label">For Soft and Smooth Face</label>
          <!-- <label class="product-label">Cleansing Foam</label> -->
          <button class="learn-btn" [routerLink]="['/newessence']">
            Learn More
          </button>
        </div>
      </div>

      <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 100%; padding-top: 80px;"
            src="/assets/skincare/powderpact.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 45px"
            >Powder Pact</label
          >
          <label class="product-label">Oil control and keep makeup</label>
          <!-- <label class="product-label">Cleansing Foam</label> -->
          <button class="learn-btn" [routerLink]="['/powderpact']">
            Learn More
          </button>
        </div>
      </div>

      <div class="col-4">
        <div class="prod-body">
          <img
            style="width: 95%; padding-top: 40px; padding-left: 12px"
            src="/assets/skincare/newtoner.png"
          />
          <label
            class="product-label"
            style="font-weight: 700; font-size: 15px; margin-top: 45px"
            >Toner</label
          >
          <label class="product-label"
            >Sensitive skin,comfortable with vegetable ingredients</label
          >
          <!-- <label class="product-label">Cleansing Foam</label> -->
          <button class="learn-btn" [routerLink]="['/newtoner']">
            Learn More
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
