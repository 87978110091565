<div class="col-xs-12 overall-container">
    <!-- About Us -->
    <div class="about-header">
        <label class="about-font">{{'banner.aboutus' | translate}}</label>
        <!-- for show -->
        <div class="aboutus-div">
            <p class="aboutus-text">
                {{'banner.aboutus6' | translate}}
                <li>{{'banner.aboutus1' | translate}}</li>
                <li>{{'banner.aboutus2' | translate}}</li>
                <li>{{'banner.aboutus3' | translate}}</li>
                <li> {{'banner.aboutus4' | translate}}</li>
                <li>{{'banner.aboutus5' | translate}}</li>
            </p>
        </div>
    </div>

    <div class="section-header center-text">
        <div class="section-title">
          <label>Founder of magic Myanmar</label><br>
        </div>
    </div>

    <!-- founder -->
    <div class="model-row">  
      <div class="column">
        <div class="founder">
          <img style="width: 100%;" src="/assets/aboutus/founder.png" />
        </div>
      </div>

      <div class="column">
        <div class="founder-title">
          <label class="title">Mrs. San Thiri Win</label><br><br>
          <label class="section-title">Founder of Magic Myanmar</label>
        </div>
      </div>
    </div>

    <div class="section-header center-text">
        <div class="section-title">
          <label >Models of Magic Myanmar</label>
        </div>
    </div>

    <div class="model-row">
      <div class="column">
        <div class="card">
          <img style="width: 95%;" src="/assets/aboutus/model-1.png" />
        </div>
      </div>

      <div class="column">
        <div class="card">
          <img style="width: 95%;" src="/assets/aboutus/model-2.png" />
        </div>
      </div>
      
      <div class="column">
        <div class="card">
          <img style="width: 95%;" src="/assets/aboutus/model-3.png" />
        </div>
      </div>
    </div>

    <div class="model-row">  
      <div class="column">
        <div class="card">
          <img style="width: 95%;" src="/assets/aboutus/model-4.png" />
        </div>
      </div>

      <div class="column">
        <div class="card">
          <img style="width: 95%;" src="/assets/aboutus/model-5.png" />
        </div>
      </div>
    </div>

    <div class="section-header center-text">
        <div class="section-title">
          <label>{{'banner.factory' | translate}}</label>
        </div>
    </div>

    <div style="margin:40px;">
        <img *ngIf="lang == 'myn'" style="width:100%;" src='/assets/aboutus/1.png' />
        <img *ngIf="lang == 'en'" style="width:100%;" src='/assets/aboutus/1_en.png' />
    </div>

    <div class="section-header center-text">
        <div class="section-title">
          <label>{{'banner.showroom' | translate}}</label>
        </div>
    </div>

    <div style="margin:40px;">
        <img *ngIf="lang == 'myn'" style="width:100%;" src='/assets/aboutus/2.png' />
        <img *ngIf="lang == 'en'" style="width:100%;" src='/assets/aboutus/2_en.png' />
    </div>


    <div class="section-header center-text">
        <div class="section-title">
          <label>{{'banner.certificate' | translate}}</label>
        </div>
    </div>

    <div style="margin:40px;">
        <img style="width:100%;" src='/assets/aboutus/3.png'>
    </div>
</div>


<style>


.column {
  width: 33.33%;
}

.model-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 64px;
    margin-bottom: 64px;
}

.model-row:after {
  content: "";
  display: table;
  clear: both;
}

.card {
  width: 350px;
  height: 500px;
}
/* Responsive columns */
@media screen and (max-width: 700px) {
    .column {
        width: 100%;
        display: block;
        margin-bottom: 20px;
        margin-left: 5%;
    }
    .model-row {
        display: block;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 40px;
        }

    .founder
    { 
        width: 380px;
        height: 550px;
    }
    .founder-title
    {
        margin-left: 10%;
    }
    .card {
        width: 350px;
        height: 500px;
    }
}

@media screen and (max-width: 375px) {
    .column {
        width: 100%;
        display: block;
        margin-bottom: 20px;
        margin-left: 5%;
    }
    .founder
    { 
        width: 350px;
        height: 480px;
    }
    .founder-title
    {
        margin-left: 5%;
    }
    .card {
        width: 320px;
        height: 440px;
    }
}
@media screen and (max-width: 320px) {
    .column {
        width: 100%;
        display: block;
        margin-bottom: 20px;
        margin-left: 5%;
    }
    .founder
    { 
        width: 300px;
        height: 440px;
    }
    .founder-title
    {
        margin-left: 2%;
    }
    .card {
        width: 270px;
        height: 400px;
    }
}
@media screen and (max-width: 280px) {
    .column {
        width: 100%;
        display: block;
        margin-bottom: 20px;
        margin-left: 5%;
    }
    .founder
    { 
        width: 260px;
        height: 400px;
    }
    .founder-title
    {
        margin-left: 2%;
    }
    .card {
        width: 230px;
        height: 350px;
    }
}

@media only screen and (min-width: 700px) {
    .column {
        width: 50%;
        display: block;
        margin-bottom: 20px;
        margin-left: 10px;
    }
    .model-row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 64px;
        margin-bottom: 64px;
        }
    .card {
        width: 220px;
        height: 300px;
    }
}
@media only screen and (min-width: 1200px) {
    .column {
      width: 33.33%;
      margin-left: 5%;
    }

    .model-row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 64px;
        margin-bottom: 64px;
    }

    .model-row:after {
      content: "";
      display: table;
      clear: both;
    }

.card {
  width: 350px;
  height: 500px;
}

}
/* Style the counter cards */

</style>