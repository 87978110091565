<div
  class="col-xs-12 overall-container"
  style="background-color: #f1f6ff; margin-top: 0% !important"
>
  <div style="margin-top: 10%; margin-bottom: 5%">
    <label class="prod-link" style="margin-left: 10%">
      <a class="prod-link" routerLink="/products">Product</a> /<a
      class="prod-link"
      routerLink="/skincareproduct"
    >Cosmetic</a
    >/ Magic Blusher
    </label>
  </div>

  <div class="detail-div">
    <div class="prod-detail-grid">
      <div class="prod-detail-cols">
        <img
          class="prod-detail-img-clens"
          src="/assets/cosmetics/Blusher.png"
        />
      </div>
      <div class="prod-detail-title">
        <label class="prod-detail-name">Magic Blusher Series</label
        ><br /><br />
        <label class="prod-detail-info"
        >"Magic Blusher Series" that will keep you glowing all day</label
        ><br /><br />
        <!--        <label class="prod-detail-info" style="font-size: 14px">Net: 80ML</label>-->
        <br /><br />
      </div>
    </div>
    <div class="rg-half-circle">
      <button class="next-btn" [routerLink]="['/eyeliner']">NEXT</button>
    </div>
  </div>

  <div class="section-header">
    <div class="section-title">
      <label>{{ "banner.productdetails" | translate }}</label>
    </div>
  </div>
  <div class="col-xs-12 overall-container" style="margin-top: 0% !important">
    <img
      *ngIf="lang == 'myn'"
      style="width: 100%"
      src="/assets/031_blusherdetail/Magic%20(Blusher%20Series)-1.png"
    />
    <img
      *ngIf="lang == 'en'"
      style="width: 100%"
      src="/assets/031_blusherdetail/Magic%20(Blusher%20Series)-Rose_en.png
"
    />
  </div>

  <div
    class="col-xs-12 overall-container"
    style="margin-top: 0% !important; text-align: center"
  >
    <img
      *ngIf="lang == 'myn'"
      style="width: 95%"
      src="/assets/031_blusherdetail/Magic%20(Blusher%20Series)-2.png"
    />
    <img
      *ngIf="lang == 'en'"
      style="width: 90%"
      src="/assets/031_blusherdetail/Magic%20(Blusher%20Series)-Nude_en.png
"
    />
  </div>

</div>
<style>
  .prod-detail-img-clens {
    width: 60%;
    padding-left: 30%;
    padding-top: 1%;
    margin-bottom: 80px;
  }
  @media only screen and (max-width: 600px) {
    .prod-detail-img-clens {
      width: 75%;
      padding-left: 20%;
      margin-bottom: 5%;
    }
  }
</style>
