import { Component } from '@angular/core';

@Component({
  selector: 'haircareproduct',
  templateUrl: './haircareproduct.component.html',
})
export class HaircareProductComponent {
  constructor() { }

  ngOnInit() {
    
  }
}
