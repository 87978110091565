<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <div style="margin-top: 10%;margin-bottom: 5%;">
        <label class="prod-link" style="margin-left: 10%;">
            <a class="prod-link" routerLink="/products">Product</a> /<a class="prod-link" routerLink="/skincareproduct">Skincare</a>/ Toner
        </label>
    </div>
    <div class="detail-div">
        <div class="prod-detail-grid">
            <div class="lf-half-circle">
                <button class="next-btn" style="margin-left: 11vw !important;" [routerLink]="['/essence']">PREV</button>
            </div>
            <div class="prod-detail-cols">
                <img class="prod-detail-img-toner" src="/assets/skincare/toner.png" />
            </div>
            <div class="prod-detail-title">
                <label class="prod-detail-name">TONER</label><br><br>
                <label class="prod-detail-info">Luxurious, Contain Treasure Activate, relieve toner</label><br><br>
                <label class="prod-detail-info" style="font-size:14px">Net: 100ml</label><br><br>
            </div>
        </div>
        <div class="rg-half-circle">
            <button class="next-btn" [routerLink]="['/cleansingwater']">NEXT</button>
        </div>
    </div>
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.productdetails' | translate}}</label>
    </div>
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;text-align: center;">
    <img *ngIf="lang == 'myn'" style="width:100%" src="/assets/8_tonerdetail/corecomponents.png" />
    <img *ngIf="lang == 'en'" style="width:90%;padding-top:7%;padding-bottom: 7%;" src="/assets/8_tonerdetail/corecomponents_en.png" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/8_tonerdetail/tip1.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/8_tonerdetail/tip1_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/8_tonerdetail/tip2.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/8_tonerdetail/tip2_en.png" />
</div>


<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/8_tonerdetail/tip3.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/8_tonerdetail/tip3_en.png" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/8_tonerdetail/tip4.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/8_tonerdetail/tip4_en.png" />
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.certis' | translate}}</label>
    </div>
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <img style="width: 72%;
    padding-top: 5%;
    padding-left: 15%;
    padding-bottom: 50px;" src="/assets/8_tonerdetail/certificate.png" />
</div>

<style>
    .prod-detail-img-toner
        {
            width: 40%; 
            padding-left: 30%;
            padding-top: 1%;
            margin-bottom: 50px;

        }
    @media only screen and (max-width: 600px) {
        .prod-detail-img-toner
        {
            width: 55%;
            padding-left: 30%;
            margin-bottom: 5%;
        }
    }
</style>