<!-- Skincare -->
<div class="col-xs-12 overall-container">
    <div style="margin-top:  2%;margin-bottom: 1%;">
        <label class="prod-link" style="margin-left: 15%;">
            <a class="prod-link" routerLink="/products">Product</a>/ Haircare
        </label>
    </div>

    <div class="about-header" style="margin-bottom: 2%;">
      <label class="title">HAIRCARE PRODUCTS</label>
    </div>
  
    <div class="container">
        <div class="row" style="margin-bottom: 50px;">
            <div class="col-4">
                <div class="prod-body">
                <img style="width: 95%;
                    padding-top: 46px;
                    padding-left: 12px;" src="/assets/haircare/soap.png" />
                <label class="product-label" style="font-weight: 700;font-size: 15px;margin-top: 75px;">Whitening Handmade Soap</label>
                <label class="product-label">Clear skin, improve calm tender and elastic</label>
                <button style="margin-top:27px;" class="learn-btn" [routerLink]="['/soap']">Learn More</button>
                </div>
            </div>
            <div class="col-4">
                <div class="prod-body">
                <img style="width: 33%;
                padding-top: 24px;
                padding-left: 98px;" src="/assets/haircare/conditioner.png" />
                <label class="product-label" style="font-weight: 700;font-size: 15px;margin-top: 75px;">Fragrance Conditioner</label>
                <label class="product-label">Enjoy Life, Frangrance Lasting and Emission Charm</label>
                <button class="learn-btn" style="margin-top:10px;" [routerLink]="['/conditioner']">Learn More</button>
                </div>
            </div>
            <div class="col-4">
                <div class="prod-body">
                <img style="width: 28%;
                padding-top: 46px;
                padding-left: 106px;" src="/assets/haircare/shampoo.png" />
                <label class="product-label" style="font-weight: 700;font-size: 15px;margin-top: 85px;">Shampoo</label>
                <label class="product-label">Enjoy Life, Frangrance Lasting and Emission Charm</label>
                <button class="learn-btn" [routerLink]="['/shampoo']">Learn More</button>
                </div>
            </div>
            <div class="col-4">
                <div class="prod-body">
                <img style="width: 33%;
                padding-top: 24px;
                padding-left: 98px;" src="/assets/haircare/showergel.png" />
                <label class="product-label" style="font-weight: 700;font-size: 15px;margin-top: 75px;">Fragrance Shower Gel</label>
                <label class="product-label">Elimination of toxins in the body and the intestine</label>
                <button class="learn-btn" style="margin-top:31px;" [routerLink]="['/gel']">Learn More</button>
                </div>
            </div>
        </div>
    </div>
    
</div>