import { Component } from '@angular/core';
import { ChangeDetection } from './shared/changedetection';

@Component({
  selector: 'esscence',
  templateUrl: './essence.component.html',
})
export class EssenceComponent {
  lang:string = 'myn';

  constructor(public changed: ChangeDetection) {
    if(localStorage.getItem('language')){
      this.lang = localStorage.getItem('language') || '';
    }
    else {
      this.lang = 'myn';
    }

    changed.notifyEvent.subscribe( data => { this.lang = data; console.log(data) });
   }

  ngOnInit() {
    
  }
}
