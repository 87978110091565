<!-- Toolbar -->
<div class="toolbar" role="banner" id="web-toolbar">
  <a routerLink="/home" (click)="myFunction(1)">
    <img class="toolbar-logo" src="/assets/logo.png" />
  </a>
  <div class="spacer">
    <a
      style="font-family: nunito; font-size: 20px; color: #192e4f"
      routerLink="/home"
      [ngClass]="active == 1 ? 'menu-item-active' : 'menu-item'"
      (click)="myFunction(1)"
    >
      {{ "banner.home" | translate }}</a
    >
    <a
      style="font-family: nunito; font-size: 20px; color: #192e4f"
      routerLink="/products"
      [ngClass]="active == 2 ? 'menu-item-active' : 'menu-item'"
      (click)="myFunction(2)"
    >
      {{ "banner.products" | translate }}</a
    >
    <a
      style="font-family: nunito; font-size: 20px; color: #192e4f"
      routerLink="/aboutus"
      [ngClass]="active == 3 ? 'menu-item-active' : 'menu-item'"
      (click)="myFunction(3)"
    >
      {{ "banner.aboutus" | translate }}</a
    >
    <a
      style="font-family: nunito; font-size: 20px; color: #192e4f"
      routerLink="/contactus"
      [ngClass]="active == 4 ? 'menu-item-active' : 'menu-item'"
      (click)="myFunction(4)"
    >
      {{ "banner.contactus" | translate }}</a
    >
  </div>
  <a aria-label="Angular on Facebook" target="_blank" rel="noopener">
    <a href="tel:+959-761-888-999" style="cursor: pointer">
      <img src="/assets/contact/phone.svg" />
    </a>
    <a
      href="tel:+959-761-888-999"
      style="
        display: inline-flex;
        vertical-align: middle;
        margin-bottom: 24px;
        font-family: nunito;
        font-weight: 500;
        font-size: 14px;
        color: #192e4f !important;
      "
      >+959-761-888-999</a
    >
    <a href="https://www.facebook.com/magicmmofficial/">
      <img
        style="margin-top: 15px; cursor: pointer"
        src="/assets/contact/facebook.svg"
      />
    </a>
  </a>
  <a *ngIf="lang == 'myn'">
    <img
      style="width: 30%; cursor: pointer"
      (click)="changeLanguage('en')"
      src="/assets/language/myanmar.png"
    />
  </a>
  <a *ngIf="lang == 'en'">
    <img
      style="width: 30%; cursor: pointer"
      (click)="changeLanguage('myn')"
      src="/assets/language/english.png"
    />
  </a>
</div>

<nav role="navigation" id="mobile-toobar">
  <div class="banner-height" role="banner">
    <div id="menuToggle">
      <input type="checkbox" />
      <span></span>
      <span></span>
      <span></span>
      <ul id="menu">
        <a
          style="font-family: nunito; font-size: 20px; color: #192e4f"
          routerLink="/home"
          (click)="myFunction(1)"
        >
          <li class="menu-links">{{ "banner.home" | translate }}</li>
        </a>
        <a
          style="font-family: nunito; font-size: 20px; color: #192e4f"
          routerLink="/products"
          (click)="myFunction(2)"
        >
          <li class="menu-links">{{ "banner.products" | translate }}</li>
        </a>
        <a
          style="font-family: nunito; font-size: 20px; color: #192e4f"
          routerLink="/aboutus"
          (click)="myFunction(3)"
        >
          <li class="menu-links">{{ "banner.aboutus" | translate }}</li>
        </a>
        <a
          style="font-family: nunito; font-size: 20px; color: #192e4f"
          routerLink="/contactus"
          (click)="myFunction(4)"
        >
          <li class="menu-links">{{ "banner.contactus" | translate }}</li>
        </a>
        <li class="social-media-links">
          <a href="tel:+959-761-888-999" style="cursor: pointer">
            <img src="/assets/contact/phone.svg" />
          </a>
          <a href="tel:+959-761-888-999" class="toolbar-phone"
            >+959-761-888-999</a
          >
          <a
            href="https://www.facebook.com/magicmmofficial/"
            style="cursor: pointer"
            ><img src="/assets/contact/facebook.svg" />
          </a>
        </li>
        <li class="language-link">
          <a *ngIf="lang == 'myn'">
            <img
              style="width: 15%; cursor: pointer"
              (click)="changeLanguage('en')"
              src="/assets/language/myanmar.png"
            />
          </a>
          <a *ngIf="lang == 'en'">
            <img
              style="width: 15%; cursor: pointer"
              (click)="changeLanguage('myn')"
              src="/assets/language/english.png"
            />
          </a>
        </li>
      </ul>
    </div>

    <div style="float: right; width: 20%; display: block">
      <a routerLink="/home" (click)="myFunction(1)">
        <img class="toolbar-logo" src="/assets/logo.png" />
      </a>
    </div>
  </div>
</nav>

<router-outlet (activate)="onActivate()"></router-outlet>

<!-- footer -->
<div class="section-header app-section-header">
  <div class="toolbar-left-div">
    <img class="logo-style" src="/assets/logo.png" />
    <div class="mrg-top-2">
      <a
        routerLink="/home"
        class="menu-item footer-menu"
        (click)="myFunction(1)"
        >{{ "banner.home" | translate }}</a
      >
      <a
        routerLink="/products"
        class="menu-item footer-menu"
        (click)="myFunction(2)"
        >{{ "banner.products" | translate }}</a
      >
      <a
        routerLink="/aboutus"
        class="menu-item footer-menu"
        (click)="myFunction(3)"
        >{{ "banner.aboutus" | translate }}</a
      >
      <a
        routerLink="/contactus"
        class="menu-item footer-menu"
        (click)="myFunction(4)"
        >{{ "banner.contactus" | translate }}</a
      >
    </div>
  </div>
  <div class="toolbar-right-div">
    <a href="tel:+959-761-888-999" style="margin-left: 20px; cursor: pointer">
      <img src="/assets/contact/phone.svg" />
    </a>
    <a
      href="tel:+959-761-888-999"
      style="
        display: inline-flex;
        vertical-align: middle;
        margin-bottom: 24px;
        margin-left: 20px;
        margin-right: 20px;
        font-family: nunito;
        font-weight: 500;
        font-size: 14px;
        color: #192e4f !important;
      "
      >+959-761-888-999</a
    >
    <a href="https://www.facebook.com/magicmmofficial/"
      ><img src="/assets/contact/facebook.svg"
    /></a>

    <div id="fb-root">
      <div
        class="fb-customerchat"
        page_id="106546240874294"
        attribution="biz_inbox"
      ></div>
    </div>
  </div>
</div>

<div class="section-header center-text" style="border-top: solid 2px #fff">
  <div>
    <label
      style="
        color: #192e4f;
        font-family: nunito;
        font-weight: 500;
        font-size: 14px;
      "
      >© All rights reserved 2021 Magic Myanmar</label
    >
    <br />
  </div>
  <div style="margin-top: 20px">
    <label
      style="
        color: #192e4f;
        font-family: nunito;
        font-weight: 500;
        font-size: 14px;
      "
      >Powered by <b style="color: #2850d5">EDKA Digital</b></label
    >
  </div>
</div>

<style>
  body {
    margin: 0;
    padding: 0;
    background: #232323;
    color: #cdcdcd;
    font-family: "Avenir Next", "Avenir", sans-serif;
  }

  #menuToggle {
    display: block;
    position: relative;
    top: 30px;
    left: 30px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    float: left;
    width: 60%;
  }

  #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }

  #menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #192e4f;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
  }

  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  #menu {
    position: absolute;
    width: 100%;
    height: 700px;
    margin: -100px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;
    background: #ffffff;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menu li {
    padding: 10px 0;
    font-size: 22px;
  }

  #menuToggle input:checked ~ ul {
    transform: none;
  }

  @media only screen and (max-width: 1000px) {
    #web-toolbar {
      display: none;
    }
  }

  @media only screen and (min-width: 1001px) {
    #mobile-toobar {
      display: none;
    }
  }
  @media only screen and (max-width: 600px) {
    #menu li.menu-links {
      padding: 20px 0;
      font-size: 20px;
      text-align: center;
    }
    li.social-media-links {
      padding: 0;
      text-align: center;
    }
    .language-link {
      padding: 10px 0;
      font-size: 20px;
      text-align: center;
    }
  }
</style>
