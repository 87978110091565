<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <div style="margin-top: 10%;margin-bottom: 5%;">
        <label class="prod-link" style="margin-left: 10%;">
            <a class="prod-link" routerLink="/products">Product</a> /<a class="prod-link" routerLink="/healthcareproduct">Healthcare</a>/Bird's Nest Collagen Jelly
        </label>
    </div>
    <div class="detail-div">
        <div class="prod-detail-grid">
            <div class="lf-half-circle">
                <button class="next-btn" style="margin-left: 11vw !important;" [routerLink]="['/loseweightset']">PREV</button>
            </div>
            <div class="prod-detail-cols">
                <img class="prod-detail-img-bird" src="/assets/healthcare/jellly.png" />
            </div>
            <div class="prod-detail-title">
                <label class="prod-detail-name">Bird's Nest Collagen Probiotics Jelly</label><br><br>
                <label class="prod-detail-info">Organic acid and probiotic baceria, elimination of toxins in the body and the intestine</label><br>
                <ul class="japan-import" style="margin-top:32px; margin-bottom: 60px;">
                    <li>
                        <div style="width:60px;float: left;">
                            <img style="width: 80%;" src="/assets/011_jellydetail/japan.png" >
                        </div>
                    </li>
                    <li><label class="prod-detail-info" style="font-size:12px;"> 
                        Import Jelly From Japan</label>
                    </li>
                    <li><label class="prod-detail-info" style="font-size:12px; margin-left: 50px;">Net: 15g x 10 pcs</label></li>
                </ul>
            </div>
        </div>
        <div class="rg-half-circle">
            <button class="next-btn" [routerLink]="['/malereplacement']">NEXT</button>
        </div>
    </div>
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.productdetails' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/011_jellydetail/corecomponents.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/011_jellydetail/corecomponents_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/011_jellydetail/tip1.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/011_jellydetail/tip1_en.png" />
</div>
<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/011_jellydetail/tip2.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/011_jellydetail/tip2_en.png" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/011_jellydetail/tip3.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/011_jellydetail/tip3_en.png" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/011_jellydetail/tip4.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/011_jellydetail/tip4_en.png" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/011_jellydetail/tip5.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/011_jellydetail/tip5_en.png" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/011_jellydetail/tip6.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/011_jellydetail/tip6_en.png" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/011_jellydetail/tip7.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/011_jellydetail/tip7_en.png" />
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.certis' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img style="width: 100%" src="/assets/011_jellydetail/certificate.jpg" />
</div>

<style>
    .prod-detail-img-bird
    {
        width: 70%; 
        padding-left: 15%;
        padding-top: 10%;
        margin-bottom: 150px;

    }
    @media only screen and (max-width: 600px) {
        .prod-detail-img-bird
        {
            width: 95%;
            padding-left: 10%;
            margin-bottom: 5%;
        }
        .prod-link-jelly
        {
            font-size: 10px;
        }
    }
</style>