<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <div style="margin-top: 10%;margin-bottom: 5%;">
        <label class="prod-link" style="margin-left: 10%;">
            <a class="prod-link" routerLink="/products">Product</a> /<a class="prod-link" routerLink="/skincareproduct">Skincare</a>/ BB Cream
        </label>
    </div>
    <div class="detail-div">
        <div class="prod-detail-grid">
            <div class="lf-half-circle">
                <button class="prev-btn" [routerLink]="['/cleanser']">PREV</button>
            </div>
            <div class="prod-detail-cols">
                <img class="prod-detail-img-bb" src="/assets/skincare/BBCream.png" />
            </div>
            <div class="prod-detail-title">
                <label class="prod-detail-name">BB CREAM</label><br><br>
                <label class="prod-detail-info">Light & Concealer</label><br><br>
                <label class="prod-detail-info" style="font-size:14px;">SPF 20/PA++</label><br><br>
                <label class="prod-detail-info" style="font-size:14px;">Net: 30g</label><br><br>
            </div>
        </div>
        <div class="rg-half-circle">
            <button class="next-btn" [routerLink]="['/sleepingmask']">NEXT</button>
        </div>
    </div>
</div>

<div class="section-header">
        <div class="section-title">
            <label>{{'banner.productdetails' | translate}}</label>
        </div>
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;text-align: center;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/2_bbcreamdetail/corecomponents.png" />
    <img *ngIf="lang == 'en'" style="width: 90%" src="/assets/2_bbcreamdetail/corecomponents_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%;" src="/assets/2_bbcreamdetail/tip1.png" />
    <img *ngIf="lang == 'en'" style="width: 100%;" src="/assets/2_bbcreamdetail/tip1_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/2_bbcreamdetail/tip2.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/2_bbcreamdetail/tip3_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/2_bbcreamdetail/tip3.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/2_bbcreamdetail/tip4_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/2_bbcreamdetail/tip5.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/2_bbcreamdetail/tip5_en.png" />
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.certis' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <img style="width: 72%;
    padding-top: 5%;
    padding-left: 15%;
    padding-bottom: 50px;" src="/assets/2_bbcreamdetail/certificate1.png" />
    <img style="width: 72%;
    padding-top: 2%;
    padding-left: 15%;
    padding-bottom: 50px;" src="/assets/2_bbcreamdetail/certificate2.png" />
</div>
<style>
    .prod-detail-img-bb
        {
            width:45%; 
            padding-left: 25%;
            margin-bottom: 50px;

        }
    @media only screen and (max-width: 600px) {
        .prod-detail-img-bb
        {   
            margin-top: 5%;
            width: 50%;
            padding-left: 35%;
            margin-bottom: 20px;
        }
    }
</style>