<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <div style="margin-top: 10%;margin-bottom: 5%;">
        <label class="prod-link" style="margin-left: 10%;">
            <a class="prod-link" routerLink="/products">Product</a> /<a class="prod-link" routerLink="/healthcareproduct">Healthcare</a>/Male Replacement Shake
        </label>
    </div>
    <div class="detail-div">
        <div class="prod-detail-grid">
            <div class="lf-half-circle">
                <button class="next-btn" style="margin-left: 11vw !important;" [routerLink]="['/loseweightset']">PREV</button>
            </div>
            <div class="prod-detail-cols">
                <img style="padding-top:0px !important;" class="prod-detail-img-bird" src="/assets/healthcare/malereplacement.png" />
            </div>
            <div class="prod-detail-title">
                <label class="prod-detail-name">Nutritional Meal Replacement Shake</label><br><br>
                <label class="prod-detail-info">6 Flavors, full meal replacement, supplement nutrition, inhibit starch absorption</label><br>
                <label class="prod-detail-info">Net: 50g</label><br><br>
            </div>
        </div>
    </div>
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.productdetails' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/016_maleshake/part2.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/016_maleshake/part2_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/016_maleshake/part3.jpg" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/016_maleshake/part3_en.jpg" />
</div>
<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/016_maleshake/part4.jpg" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/016_maleshake/part4_en.jpg" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/016_maleshake/part5.jpg" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/016_maleshake/part5_en.jpg" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/016_maleshake/part6.jpg" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/016_maleshake/part6_en.jpg" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn' || lang == 'en'" style="width: 100%" src="/assets/016_maleshake/part10.jpg" />

</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/016_maleshake/part7.jpg" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/016_maleshake/part7_en.jpg" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/016_maleshake/part8.jpg" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/016_maleshake/part8_en.jpg" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/016_maleshake/part9.jpg" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/016_maleshake/part9_en.jpg" />
</div>

<!-- <div class="section-header">
    <div class="section-title">
        <label>{{'banner.certis' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img style="width: 100%" src="/assets/016_maleshake/certificate.jpg" />
</div> -->

<style>
    .prod-detail-img-bird
    {
        width: 70%; 
        padding-left: 15%;
        padding-top: 10%;
        margin-bottom: 150px;

    }
    @media only screen and (max-width: 600px) {
        .prod-detail-img-bird
        {
            width: 95%;
            padding-left: 10%;
            margin-bottom: 5%;
        }
        .prod-link-jelly
        {
            font-size: 10px;
        }
    }
</style>