<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <div style="margin-top: 10%;margin-bottom: 5%;">
        <label class="prod-link" style="margin-left: 10%;">
            <a class="prod-link" routerLink="/products">Product</a> /<a class="prod-link"
                routerLink="/skincareproduct">Skincare</a>/ Sun Cream
        </label>
    </div>
    <div class="detail-div">
        <div class="prod-detail-grid">
            <div class="lf-half-circle">
                <button class="next-btn" style="margin-left: 11vw !important;"
                    [routerLink]="['/cleansingwater']">PREV</button>
            </div>
            <div class="prod-detail-cols">
                <img class="prod-detail-img-suncream" src="/assets/skincare/suncream.png" />
            </div>
            <div class="prod-detail-title">
                <label class="prod-detail-name">MAGIC SUNCREAM</label><br><br>
                <label class="prod-detail-info">The SPF 50+/PA+++ sunscreen can keep the skin youthful and
                    healthy.</label><br><br>
                <div style="display: flex">
                    <img style="width: 80px; height: 50px;" src="/assets/korean.png" />
                    <div style="display:flex; flex-direction:column; justify-content:center; align-items: center;padding-left: 10px;">
                        <label class="prod-detail-info" style="font-size:14px;">Made In South Korea</label>
                    </div>
                    <div style="display:flex; flex-direction:column; justify-content:center; align-items: center;padding-left: 10px;">
                        <label class="prod-detail-info" style="font-size:14px; padding-left: 20px;">Net: 200ml</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="rg-half-circle">
            <button class="next-btn" [routerLink]="['/bodylotion']">NEXT</button>
        </div>
    </div>
</div>
<div class="section-header">
    <div class="section-title">
        <label>{{'banner.productdetails' | translate}}</label>
    </div>
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;text-align: center;">
    <img *ngIf="lang == 'myn'" style="width:100%" src="/assets/019_suncream/corecomponents.png" />
    <img *ngIf="lang == 'en'" style="width:90%;padding-top:7%;padding-bottom: 7%;"
        src="/assets/019_suncream/corecomponents_en.png" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/019_suncream/tip1.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/019_suncream/tip1_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/019_suncream/tip2.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/019_suncream/tip2_en.png" />
</div>


<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/019_suncream/tip3.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/019_suncream/tip3_en.png" />
</div>

<style>
    .prod-detail-img-suncream {
        width: 80%;
        padding-left: 20%;
        margin-bottom: 130px;
    }

    @media only screen and (max-width: 600px) {
        .prod-detail-img-suncream {
            margin-top: 5%;
            width: 80%;
            padding-left: 20%;
            margin-bottom: 5px;
        }
    }
</style>