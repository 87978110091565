import { Component } from '@angular/core';

@Component({
  selector: 'contactus',
  templateUrl: './contactus.component.html',
})
export class ContactusComponent {
  constructor() { }

  ngOnInit() {
    
  }
}
