<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <div style="margin-top: 10%;margin-bottom: 5%;">
        <label class="prod-link" style="margin-left: 10%;">
            <a class="prod-link" routerLink="/products">Product</a> /<a class="prod-link" routerLink="/healthcareproduct">Healthcare</a>/Morning And Night Toothpaste
        </label>
    </div>
    <div class="detail-div">
        <div class="prod-detail-grid">
            <div class="lf-half-circle">
                <button class="next-btn" style="margin-left: 11vw !important;" [routerLink]="['/gel']">PREV</button>
            </div>
            <div class="prod-detail-cols">
                <img class="prod-detail-img-bird" style="width:50%;" src="/assets/healthcare/toothpaste.png" />
            </div>
            <div class="prod-detail-title">
                <label class="prod-detail-name">MORNING AND NIGHT TOOTHPASTE</label><br><br>
                <label class="prod-detail-info">Fresh mist Morning toothpaste and nighttime toothpaste extracted from natural lavener</label><br>
                <ul class="japan-import" style="margin-top:32px; margin-bottom: 60px;">
                    <li><label class="prod-detail-info" style="font-size:12px;">Net: 180g</label></li>
                </ul>
                
            </div>
        </div>
    </div>
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.productdetails' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/017_toothpaste/corecomponents.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/017_toothpaste/corecomponents_en.jpg" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/017_toothpaste/tip1.jpg" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/017_toothpaste/tip1_en.jpg" />
</div>
<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/017_toothpaste/tip2.jpg" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/017_toothpaste/tip2_en.jpg" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/017_toothpaste/tip3.jpg" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/017_toothpaste/tip3_en.jpg" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn' || lang == 'en'" style="width: 100%" src="/assets/017_toothpaste/tip6.jpg" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/017_toothpaste/tip4.jpg" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/017_toothpaste/tip4_en.jpg" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/017_toothpaste/tip5.jpg" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/017_toothpaste/tip5_en.jpg" />
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.certis' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img style="width: 97%;margin: 10px;" src="/assets/017_toothpaste/certificates.png" />
</div>

<style>
    .prod-detail-img-bird
    {
        width: 70%; 
        padding-left: 15%;
        padding-top: 10%;
        margin-bottom: 150px;

    }
    @media only screen and (max-width: 600px) {
        .prod-detail-img-bird
        {
            width: 95%;
            padding-left: 10%;
            margin-bottom: 5%;
        }
        .prod-link-jelly
        {
            font-size: 10px;
        }
    }
</style>