<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">

    <div style="margin-top: 10%;margin-bottom: 5%;">
        <label class="prod-link" style="margin-left: 10%;">
            <a class="prod-link" routerLink="/products">Product</a> /<a class="prod-link" routerLink="/skincareproduct">Skincare</a>/ Cleanser
        </label>
    </div>

    <div class="detail-div">
        <div class="prod-detail-grid">
            <div class="prod-detail-cols">
                <img class="prod-detail-img-clens" src="/assets/skincare/cleanser.png" />
            </div>
            <div class="prod-detail-title">
                <label class="prod-detail-name">CLEANSER</label><br><br>
                <label class="prod-detail-info">Soft Shining and Moisturizing Amino Acid Facial Cleanser</label><br><br>
                <label class="prod-detail-info" style="font-size:14px;">Net: 100g</label><br><br>
            </div>
        </div>
        <div class="rg-half-circle">
            <button class="next-btn" [routerLink]="['/bbcream']">NEXT</button>
        </div>
    </div>

    <div class="section-header">
        <div class="section-title">
            <label>{{'banner.productdetails' | translate}}</label>
        </div>
    </div>
    <div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
        <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/1_cleanserdetail/corecomponents.png" />
        <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/1_cleanserdetail/corecomponents_eng.png" />
    </div>

    <div class="col-xs-12 overall-container" style="margin-top: 0% !important;text-align: center;">
        <img *ngIf="lang == 'myn'" style="width: 95%" src="/assets/1_cleanserdetail/part3.png" />
        <img *ngIf="lang == 'en'" style="width: 90%" src="/assets/1_cleanserdetail/part3_en.png" />
    </div>

    <div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
        <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/1_cleanserdetail/part4.png" />
        <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/1_cleanserdetail/part4_en.png" />
    </div>

    <div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
        <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/1_cleanserdetail/part5.png" />
        <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/1_cleanserdetail/part5_en.png" />
    </div>

    <div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
        <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/1_cleanserdetail/part6.png" />
        <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/1_cleanserdetail/part6_en.png" />
    </div>

    <div class="section-header">
        <div class="section-title">
            <label>{{'banner.certis' | translate}}</label>
        </div>
    </div>
    <div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
        <img style="width: 72%;
        padding-top: 5%;
        padding-left: 15%;
        padding-bottom: 50px;" src="/assets/1_cleanserdetail/certificate.png" />
    </div>
</div>
<style>
    .prod-detail-img-clens
        {
            width: 60%; 
            padding-left: 30%;
            padding-top: 1%;
            margin-bottom: 80px;

        }
    @media only screen and (max-width: 600px) {
        .prod-detail-img-clens
        {
            width: 75%;
            padding-left: 20%;
            margin-bottom: 5%;
        }
    }
</style>