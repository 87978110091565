<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <div style="margin-top: 10%;margin-bottom: 5%;">
        <label class="prod-link" style="margin-left: 10%;">
            <a class="prod-link" routerLink="/products">Product</a> /<a class="prod-link" routerLink="/healthcareproduct">Healthcare</a>/ Magic Lose Weight Set
        </label>
    </div>
    <div class="detail-div">
        <div class="prod-detail-grid">
            <div class="lf-half-circle">
                <button class="next-btn" style="margin-left: 11vw !important;" [routerLink]="['/collagen']">PREV</button>
            </div>
            <div class="prod-detail-cols">
                <img class="prod-detail-img-lose" src="/assets/healthcare/weightloss.png" />
            </div>
            <div class="prod-detail-title">
                <label class="prod-detail-name">MAGIC LOSE WEIGHT SET</label><br><br>
                <label class="prod-detail-info">Hot compress to lose weight safely and healthy</label><br><br>
            </div>
        </div>
        <div class="rg-half-circle">
            <button class="next-btn" [routerLink]="['/jelly']">NEXT</button>
        </div>
    </div>
    
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.productdetails' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/010_weightlosdetail/corecomponents.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/010_weightlosdetail/corecomponents_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;text-align: center;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/010_weightlosdetail/tip1.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/010_weightlosdetail/tip1_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width:100%" src="/assets/010_weightlosdetail/tip2.png" />
    <img *ngIf="lang == 'en'" style="width:100%" src="/assets/010_weightlosdetail/tip2_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;text-align: center;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/010_weightlosdetail/tip3.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/010_weightlosdetail/tip3_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;text-align: center;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/010_weightlosdetail/tip4.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/010_weightlosdetail/tip4_en.png" />
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.certis' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <img style="width: 100%" src="/assets/010_weightlosdetail/certificate.png" />
</div>


<style>
    .prod-detail-img-lose
        {
            width: 80%; 
            padding-left: 10%;
            margin-bottom: 10px;

        }
    @media only screen and (max-width: 600px) {
        .prod-detail-img-lose
        {
            width: 80%;
            padding-left: 15%;
            margin-bottom: 5%;
        }
    }
</style>