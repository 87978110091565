<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <div style="margin-top: 10%;margin-bottom: 5%;">
        <label class="prod-link" style="margin-left: 10%;">
            <a class="prod-link" routerLink="/products">Product</a> /<a class="prod-link" routerLink="/skincareproduct">Skincare</a>/ Emulsion
        </label>
    </div>
    <div class="detail-div">
        <div class="prod-detail-grid">
            <div class="lf-half-circle">
                <button class="next-btn" style="margin-left: 11vw !important;" [routerLink]="['/powder']">PREV</button>
            </div>
            <div class="prod-detail-cols">
                <img class="prod-detail-img-emul" src="/assets/skincare/emulsion.png" />
            </div>
            <div class="prod-detail-title">
                <label class="prod-detail-name">EMULSION</label><br><br>
                <label class="prod-detail-info">Moisturizing and Light Protection Emulsion</label><br><br>
                <label class="prod-detail-info">SPF30</label><br><br>
                <label class="prod-detail-info" style="font-size:14px;">Net: 60ml</label><br><br>
            </div>
        </div>
        <div class="rg-half-circle">
            <button class="next-btn" [routerLink]="['/facialmask']">NEXT</button>
        </div>
    </div>
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.productdetails' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/5_emulsiondetail/corecomponents.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/5_emulsiondetail/corecomponents_en.png" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%;padding-top: 5%;" src="/assets/5_emulsiondetail/part3.png" />
    <img *ngIf="lang == 'en'" style="width: 100%;padding-top: 5%;" src="/assets/5_emulsiondetail/part3_en.png" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%;padding-top: 5%;" src="/assets/5_emulsiondetail/part4.png" />
    <img *ngIf="lang == 'en'" style="width: 100%;padding-top: 5%;" src="/assets/5_emulsiondetail/part4_en.png" />
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.certis' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img style="width: 72%;
    padding-top: 5%;
    padding-left: 15%;
    padding-bottom: 50px;" src="/assets/5_emulsiondetail/certificate.png" />
</div>

<style>
    .prod-detail-img-emul
        {
            width: 35%; 
            padding-left: 30%;
            padding-top: 1%;
            margin-bottom: 80px;

        }
    @media only screen and (max-width: 600px) {
        .prod-detail-img-emul
        {
            width: 45%;
            padding-left: 35%;
            margin-bottom: 10%;
        }
    }
</style>