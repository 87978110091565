<!-- Skincare -->
<div class="col-xs-12 overall-container">
    <div style="margin-top:  3%; margin-bottom: 1%;">
        <label class="prod-link" style="margin-left: 15%;">
            <a class="prod-link" routerLink="/products">Product</a> /Healthcare
        </label>
    </div>

    <div class="about-header" style="margin-bottom: 2%;">
      <label class="title">HEALTHCARE PRODUCTS</label>
    </div>
  
   <div class="container">
    <div class="row" style="margin-bottom: 50px;">
      <div class="col-4">
        <div class="prod-body">
          <img style="width: 95%;
          padding-top: 80px;
          padding-left: 12px;" src="/assets/healthcare/collagen.png" />
          <label class="product-label" style="font-weight: 700;font-size: 15px;margin-top: 85px;">Collagen Probiotics Solid Beverage</label>
          <label class="product-label">Peptide Collagen and get rid of lines and wrinkles</label>
          <button class="learn-btn" [routerLink]="['/collagen']">{{'banner.learnmore' | translate}}</button>
        </div>
      </div>
      <div class="col-4">
        <div class="prod-body">
          <img style="width: 90%;
          padding-top: 40px;
          padding-left: 20px;" src="/assets/healthcare/weightloss.png" />
          <label class="product-label" style="font-weight: 700;font-size: 15px;margin-top:45px;">Magic Lose Weight Set</label>
          <label class="product-label">Hot compress to lose weight safely and healthy</label>
          <button class="learn-btn" [routerLink]="['/loseweightset']">{{'banner.learnmore' | translate}}</button>
        </div>
      </div>
      <div class="col-4">
        <div class="prod-body">
          <img style="width: 85%;
            padding-top: 90px;
            padding-left: 20px;" src="/assets/healthcare/jellly.png" />
          <label class="product-label" style="font-weight: 700;font-size: 15px;margin-top: 124px;">Bird's Nest Collagen Probiotics Jelly</label>
      
          <label class="product-label">Elimination of toxins in the body and the intestine</label>
          <button class="learn-btn" [routerLink]="['/jelly']">{{'banner.learnmore' | translate}}</button>
        </div>
      </div>
      <div class="col-4">
        <div class="prod-body">
          <img style="width: 77%;
          padding-top: 23px;
          padding-left: 34px;" src="/assets/healthcare/malereplacement.png" />
          <label class="product-label" style="font-weight: 600; margin-top: 123px;">Nutritional Meal Replacement Shake</label>
          <label class="product-label">6 Flavors, full meal replacement, supplement nutrition, inhibit starch absorption</label>
          <button class="learn-btn" [routerLink]="['/malereplacement']">{{'banner.learnmore' | translate}}</button>
        </div>
      </div>
      <div class="col-4">
        <div class="prod-body">
          <img style="width: 77%;
          padding-top: 23px;
          padding-left: 34px;" src="/assets/healthcare/toothpaste.png" />
          <label class="product-label" style="font-weight: 600; margin-top: 91px;">Morning And Night Toothpaste</label>
          <label class="product-label">Fresh mist Morning toothpaste and nighttime toothpaste extracted from natural lavener</label>
          <button class="learn-btn" [routerLink]="['/toothpaste']">{{'banner.learnmore' | translate}}</button>
        </div>
      </div>
    </div>
</div>