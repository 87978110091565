import { Component } from '@angular/core';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  startIndex = 0;
  Imagedata:string[] = ['/assets/Carousel/1.png', '/assets/Carousel/2.png', '/assets/Carousel/3.png', '/assets/Carousel/4.jpg',  '/assets/Carousel/5.jpg', '/assets/Carousel/6.jpg', '/assets/Carousel/7.jpg'];
  active = 1;
  skinpage:number = 1;
  healthpage:number = 1;
  hairpage:number = 1;
  slideConfig = {"ImagedataToShow": 4, "ImagedataToScroll": 4, "autoplay":true, "autoplaySpeed": 5000};

  constructor() { 
  }

  ngOnInit() {
  }

  addSlide() {
    this.Imagedata.push( "http://placehold.it/350x150/777777")
  }
  
  removeSlide() {
    this.Imagedata.length = this.Imagedata.length - 1;
  }
  
  slickInit(e:any) {
    console.log('slick initialized');
  }
  
  afterChange(e:any) {
    console.log('afterChange');
  }
  
  beforeChange(e:any) {
    console.log('beforeChange');
  }

  myFunction(data:any) {
    this.active = data;
  }

  showskinpage(param:number){
    this.skinpage = param;
  }

  showhealthpage(param:number){
    this.healthpage = param
  }

  showhairpage(param:number){
    this.hairpage = param;
  }
}
