<div
  class="col-xs-12 overall-container"
  style="background-color: #f1f6ff; margin-top: 0% !important"
>
  <div style="margin-top: 10%; margin-bottom: 5%">
    <label class="prod-link" style="margin-left: 10%">
      <a class="prod-link" routerLink="/products">Product</a> /<a
      class="prod-link"
      routerLink="/skincareproduct"
    >Cosmetic</a
    >/ Eyebrow Pencil
    </label>
  </div>

  <div class="detail-div">
    <div class="prod-detail-grid">
      <div class="prod-detail-cols">
        <img
          class="prod-detail-img-clens"
          src="/assets/cosmetics/Eyebrow.png"
        />
      </div>
      <div class="prod-detail-title">
        <label class="prod-detail-name"> Eyebrow Pencil</label
        ><br /><br />
        <label class="prod-detail-info"
        >"Doubled Headed Eyebrow Pencil" for perfectly groomed eyebrows</label
        ><br /><br />
<!--        <label class="prod-detail-info" style="font-size: 14px">Net: 80ML</label-->
<!--        ><br /><br />-->
      </div>
    </div>
    <div class="rg-half-circle">
      <button class="next-btn" [routerLink]="['/eyeshadow']">NEXT</button>
    </div>
  </div>

  <div class="section-header">
    <div class="section-title">
      <label>{{ "banner.productdetails" | translate }}</label>
    </div>
  </div>
  <div class="col-xs-12 overall-container" style="margin-top: 0% !important">
    <img
      *ngIf="lang == 'myn'"
      style="width: 100%"
      src="/assets/029_eyebrowpencildetail/Magic%20%20(Eyebrow%20Pencil).png"
    />
    <img
      *ngIf="lang == 'en'"
      style="width: 100%"
      src="/assets/029_eyebrowpencildetail/Magic%20%20(Eyebrow%20Pencil)_en.png
"
    />
  </div>

<!--  <div-->
<!--    class="col-xs-12 overall-container"-->
<!--    style="margin-top: 0% !important; text-align: center"-->
<!--  >-->
<!--    <img-->
<!--      *ngIf="lang == 'myn'"-->
<!--      style="width: 95%"-->
<!--      src="/assets/024_daycreamdetail/part3.jpg"-->
<!--    />-->
<!--    <img-->
<!--      *ngIf="lang == 'en'"-->
<!--      style="width: 90%"-->
<!--      src="/assets/024_daycreamdetail/part3_en.jpg"-->
<!--    />-->
<!--  </div>-->
  <!--
  <div class="col-xs-12 overall-container" style="margin-top: 0% !important">
    <img
      *ngIf="lang == 'myn'"
      style="width: 100%"
      src="/assets/1_cleanserdetail/part4.png"
    />
    <img
      *ngIf="lang == 'en'"
      style="width: 100%"
      src="/assets/1_cleanserdetail/part4_en.png"
    />
  </div> -->

<!--  <div class="col-xs-12 overall-container" style="margin-top: 0% !important">-->
<!--    <img-->
<!--      *ngIf="lang == 'myn'"-->
<!--      style="width: 100%"-->
<!--      src="/assets/024_daycreamdetail/part5.jpg"-->
<!--    />-->
<!--    <img-->
<!--      *ngIf="lang == 'en'"-->
<!--      style="width: 100%"-->
<!--      src="/assets/024_daycreamdetail/part5_en.jpg"-->
<!--    />-->
<!--  </div>-->

<!--  <div class="col-xs-12 overall-container" style="margin-top: 0% !important">-->
<!--    <img-->
<!--      *ngIf="lang == 'myn'"-->
<!--      style="width: 100%"-->
<!--      src="/assets/024_daycreamdetail/part6.jpg"-->
<!--    />-->
<!--    <img-->
<!--      *ngIf="lang == 'en'"-->
<!--      style="width: 100%"-->
<!--      src="/assets/024_daycreamdetail/part6_en.jpg"-->
<!--    />-->
<!--  </div>-->

<!--  <div class="section-header">-->
<!--    <div class="section-title">-->
<!--      <label>{{ "banner.certis" | translate }}</label>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div-->
<!--    class="col-xs-12 overall-container"-->
<!--    style="background-color: #f1f6ff; margin-top: 0% !important"-->
<!--  >-->
<!--    <img-->
<!--      style="-->
<!--        width: 72%;-->
<!--        padding-top: 5%;-->
<!--        padding-left: 15%;-->
<!--        padding-bottom: 50px;-->
<!--      "-->
<!--      src="/assets/024_daycreamdetail/certificate.jpg"-->
<!--    />-->
<!--  </div>-->
</div>
<style>
  .prod-detail-img-clens {
    width: 60%;
    padding-left: 30%;
    padding-top: 1%;
    margin-bottom: 80px;
  }
  @media only screen and (max-width: 600px) {
    .prod-detail-img-clens {
      width: 75%;
      padding-left: 20%;
      margin-bottom: 5%;
    }
  }
</style>
