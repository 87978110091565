import { Component } from '@angular/core';

@Component({
  selector: 'healthcareproduct',
  templateUrl: './healthcareproduct.component.html',
})
export class HealthcareProductComponent {
  constructor() { }

  ngOnInit() {
    
  }
}
