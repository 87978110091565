<div class="col-xs-12 overall-container">
  <div class="cate-div">
    <div class="prod-cate">
      <div class="cate-img">
        <a routerLink="/skincareproduct">
          <img style="width: 100%" src="/assets/prod_cate/skincare.png" />
        </a>
      </div>
    </div>
    <div class="prod-cate">
      <div class="cate-img">
        <a routerLink="/healthcareproduct">
          <img style="width: 100%" src="/assets/prod_cate/healthcare.png" />
        </a>
      </div>
    </div>
    <div class="prod-cate">
      <div class="cate-img">
        <a routerLink="/haircareproduct">
          <img style="width: 100%" src="/assets/prod_cate/haircare.png" />
        </a>
      </div>
    </div>
  </div>

  <div class="section-header">
    <div class="section-title">
      <label>{{ "banner.ourproducts" | translate }}</label>
    </div>
  </div>

  <!-- Skincare -->
  <div class="about-header">
    <label class="title">{{ "banner.skincareproducts" | translate }}</label>
  </div>

  <!-- <div class="prod-div" *ngIf="skinpage == 1">
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 95%; padding-top: 20px; padding-left: 12px"
          src="/assets/skincare/cleanser.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 28px"
          >Cleanser</label
        >
        <label class="product-label"
          >Soft Shining and Moisturizing Amino Acid</label
        >
        <label class="product-label">Facial Cleanser</label>
        <button class="learn-btn" [routerLink]="['/cleanser']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 60%; padding-top: 38px; padding-left: 61px"
          src="/assets/skincare/BBCream.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 30px"
          >BB Cream</label
        >
        <label class="product-label">Light and Concealer</label>
        <button
          class="learn-btn"
          style="margin-top: 45px !important"
          [routerLink]="['/bbcream']"
        >
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 95%; padding-top: 20px; padding-left: 12px"
          src="/assets/skincare/sleepingmask.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 30px"
          >Sleeping Mask</label
        >
        <label class="product-label">Peptide Repair Facial Sleeping Mask</label>
        <button
          class="learn-btn"
          style="margin-top: 45px !important"
          [routerLink]="['/sleepingmask']"
        >
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
  </div> -->

  <!-- <div class="prod-div" *ngIf="skinpage == 2">
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 95%; padding-top: 66px; padding-left: 12px"
          src="/assets/skincare/powder.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 40px"
          >Setting Powder</label
        >
        <label class="product-label">Magic Featherlight Setting Powder</label>
        <button
          style="margin-top: 35px"
          class="learn-btn"
          [routerLink]="['/powder']"
        >
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 54%; padding-top: 39px; padding-left: 70px"
          src="/assets/skincare/emulsion.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 28px"
          >Emulsion</label
        >
        <label class="product-label"
          >Moisturizing and Light Protection Emulsion</label
        >
        <button
          class="learn-btn"
          style="margin-top: 15px"
          [routerLink]="['/emulsion']"
        >
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 95%; padding-top: 70px; padding-left: 12px"
          src="/assets/skincare/facialmask.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 40px"
          >Facial Mask</label
        >
        <label class="product-label"
          >Sooth Tender and Shining Facial Mask</label
        >
        <button
          style="margin-top: 35px"
          class="learn-btn"
          [routerLink]="['/facialmask']"
        >
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
  </div> -->

  <div class="prod-div" *ngIf="skinpage == 1">
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 90%; padding-top: 35px; padding-left: 10px"
          src="/assets/skincare/cleansingwater.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 51px"
          >Cleansing Water</label
        >
        <label class="product-label"
          >99% mild cleansing with just 1 cotton pad</label
        >
        <button class="learn-btn" [routerLink]="['/cleansingwater']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 90%; padding-top: 38px; padding-left: 10px"
          src="/assets/skincare/suncream.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 27px"
          >Sun Cream</label
        >
        <label class="product-label"
          >The SPF 50+/PA+++ sunscreen can keep the skin youthful and
          healthy.</label
        >
        <button class="learn-btn" [routerLink]="['/suncream']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 90%; padding-top: 38px; padding-left: 25px"
          src="/assets/skincare/bodylotion.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 26px"
          >Body Lotion</label
        >
        <label class="product-label"
          >a daily-use body lotion that moisturizes the skin and doesn't leave
          a sticky residue</label
        >
        <button class="learn-btn" [routerLink]="['/bodylotion']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="prod-div" *ngIf="skinpage == 2">
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 80%; padding-top: 38px; padding-left: 25px"
          src="/assets/skincare/newcleanser.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 20px"
          >Cleanser</label
        >
        <label class="product-label"
          >Daily Peeling & Washing 2 in 1 cleansing foam</label
        >
        <button class="learn-btn" [routerLink]="['/newcleanser']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 90%; padding-top: 75px; padding-left: 10px"
          src="/assets/skincare/nightcream.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 59px"
          >Night Cream</label
        >
        <label class="product-label">Recovering Tired Skin Over Night</label>
        <button class="learn-btn" [routerLink]="['/nightcream']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 90%; padding-top: 75px; padding-left: 10px"
          src="/assets/skincare/cushion.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 33px"
          >Cushion</label
        >
        <label class="product-label"
          >To prevent the sunlight and provides extra layer for the skin</label
        >
        <button class="learn-btn" [routerLink]="['/cushion']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>

  </div>

  <div class="prod-div" *ngIf="skinpage == 3">
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 90%; padding-top: 48px; padding-left: 10px"
          src="/assets/skincare/daycream.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 53px"
          >Day Cream</label
        >
        <label class="product-label"
          >Smoothens,Softens and Refreshes The Fical Skin</label
        >
        <button class="learn-btn" [routerLink]="['/daycream']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 90%; padding-top: 60px; padding-left: 10px"
          src="/assets/skincare/newessence.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 65px"
          >Essence</label
        >
        <label class="product-label">For Soft and Smooth Face</label>
        <button class="learn-btn" [routerLink]="['/newessence']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 90%; padding-top:100px; padding-left: 10px"
          src="/assets/skincare/powderpact.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 65px"
          >Powder Pact</label
        >
        <label class="product-label">Oil control and keep makeup</label>
        <button class="learn-btn" [routerLink]="['/powderpact']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="prod-div" *ngIf="skinpage == 4">
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 90%; padding-top: 45px; padding-left: 20px"
          src="/assets/skincare/newtoner.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 48px"
          >Toner</label
        >
        <label class="product-label"
          >Sensitive skin,comfortable with vegetable ingredients</label
        >
        <button class="learn-btn" [routerLink]="['/newtoner']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div
    class="about-header"
    style="margin-bottom: 1%; margin-top: 0px !important"
  >
    <button class="hand slim-btn" (click)="showskinpage(1)"></button>
    <button class="hand slim-btn" (click)="showskinpage(2)"></button>
    <button class="hand slim-btn" (click)="showskinpage(3)"></button>
    <button class="hand slim-btn" (click)="showskinpage(4)"></button>
    <!-- <button class="hand slim-btn" (click)="showskinpage(5)"></button> -->
  </div>
  <div
    class="about-header mrg-btm-5"
    style="margin-bottom: 2%; margin-top: 0px !important"
  >
    <a class="hand" routerLink="/skincareproduct">{{
      "banner.viewallskincare" | translate
    }}</a>
  </div>

  <!-- Healthcare -->
  <div class="about-header">
    <label class="title">{{ "banner.healthcare" | translate }}</label>
  </div>

  <div class="prod-div" *ngIf="healthpage == 1">
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 95%; padding-top: 80px; padding-left: 12px"
          src="/assets/healthcare/collagen.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 90px"
          >Collagen Probiotics Solid Beverage</label
        >
        <label class="product-label"
          >Peptide Collagen and get rid of lines and wrinkles</label
        >
        <button class="learn-btn" [routerLink]="['/collagen']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 90%; padding-top: 40px; padding-left: 22px"
          src="/assets/healthcare/weightloss.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 48px"
          >Magic Lose Weight Set</label
        >
        <label class="product-label"
          >Hot compress to lose weight safely and healthy</label
        >
        <button
          class="learn-btn"
          style="margin-top: 15px"
          [routerLink]="['/loseweightset']"
        >
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 85%; padding-top: 90px; padding-left: 20px"
          src="/assets/healthcare/jellly.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 125px"
          >Bird's Nest Collagen Probiotics Jelly</label
        >
        <label class="product-label"
          >Elimination of toxins in the body and the intestine</label
        >
        <button class="learn-btn" [routerLink]="['/jelly']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="prod-div" *ngIf="healthpage == 2">
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 77%; padding-top: 23px; padding-left: 34px"
          src="/assets/healthcare/malereplacement.png"
        />
        <label class="product-label" style="font-weight: 600; margin-top: 112px"
          >Nutritional Meal Replacement Shake</label
        >
        <label class="product-label"
          >6 Flavors, full meal replacement, supplement nutrition, inhibit
          starch absorption</label
        >
        <button class="learn-btn" [routerLink]="['/malereplacement']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 95%; padding-top: 20px; padding-left: 12px"
          src="/assets/healthcare/toothpaste.png"
        />
        <label class="product-label" style="font-weight: 600; margin-top: 30px"
          >Morning And Night Tothpaste</label
        >
        <label class="product-label"
          >Fresh mist Morning toothpaste and nighttime toothpaste extracted from
          natural lavener</label
        >
        <button class="learn-btn" [routerLink]="['/toothpaste']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div
    class="about-header"
    style="margin-bottom: 1%; margin-top: 20px !important"
  >
    <button class="hand slim-btn" (click)="showhealthpage(1)"></button>
    <button class="hand slim-btn" (click)="showhealthpage(2)"></button>
  </div>

  <div
    class="about-header"
    style="margin-bottom: 2%; margin-top: 10px !important"
  >
    <a class="hand" routerLink="/healthcareproduct">{{
      "banner.viewallhealthcare" | translate
    }}</a>
  </div>

  <!-- Haircare -->
  <div class="about-header">
    <label class="title">{{ "banner.haircare" | translate }}</label>
  </div>

  <div class="prod-div" *ngIf="hairpage == 1">
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 95%; padding-top: 46px; padding-left: 12px"
          src="/assets/haircare/soap.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 75px"
          >Whitening Handmade Soap</label
        >
        <label class="product-label"
          >Clear skin, improve calm tender and elastic</label
        >
        <button
          style="margin-top: 35px"
          class="learn-btn"
          [routerLink]="['/soap']"
        >
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 33%; padding-top: 24px; padding-left: 98px"
          src="/assets/haircare/conditioner.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 75px"
          >Fragrance Conditioner</label
        >
        <label class="product-label"
          >Hot compress to lose weight safely and healthy</label
        >
        <button
          class="learn-btn"
          style="margin-top: 15px"
          [routerLink]="['/conditioner']"
        >
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 28%; padding-top: 46px; padding-left: 106px"
          src="/assets/haircare/shampoo.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 90px"
          >Shampoo</label
        >
        <label class="product-label"
          >Enjoy Life, Frangrance Lasting and Emission Charm</label
        >
        <button class="learn-btn" [routerLink]="['/shampoo']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="prod-div" *ngIf="hairpage == 2">
    <div class="prod-responsive">
      <div class="prod-body">
        <img
          style="width: 33%; padding-top: 24px; padding-left: 98px"
          src="/assets/haircare/showergel.png"
        />
        <label
          class="product-label"
          style="font-weight: 600; font-size: 15px; margin-top: 85px"
          >Fragrance Shower Gel</label
        >
        <label class="product-label"
          >Elimination of toxins in the body and the intestine</label
        >
        <button class="learn-btn" [routerLink]="['/gel']">
          {{ "banner.learnmore" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div
    class="about-header"
    style="margin-bottom: 1%; margin-top: 0px !important"
  >
    <button class="hand slim-btn" (click)="showhairpage(1)"></button>
    <button class="hand slim-btn" (click)="showhairpage(2)"></button>
  </div>

  <div
    class="about-header"
    style="margin-bottom: 64px; margin-top: 0px !important"
  >
    <a class="hand" routerLink="/haircareproduct">{{
      "banner.viewallhaircare" | translate
    }}</a>
  </div>


  <!-- COSMETICS -->
  <div class="about-header">
    <label class="title">{{'banner.cosmetics' | translate}}</label>
  </div>

  <div class="prod-div" *ngIf="hairpage == 1">
    <div class="prod-responsive">
      <div class="prod-body">
        <img style="width: 100%;padding-top: 0px;padding-left: 8px;" src="/assets/cosmetics/Lip-6.png" />
        <label class="product-label" style="font-weight: 600; margin-top: 40px;">Lipstick</label>
        <label class="product-label">"Magic Lipstick" that every girl love</label>
        <button style="margin-top: 35px;" class="learn-btn" [routerLink]="['/lipstick']">{{'banner.learnmore' | translate}}</button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img style="width: 80%;padding-top: 10px;padding-left: 25px;" src="/assets/cosmetics/Eyebrow.png" />
        <label class="product-label" style="font-weight: 600; margin-top: 33px;">Eyebrow Pencil</label>
        <label class="product-label">"Doubled Headed Eyebrow Pencil" for perfectly groomed eyebrows</label>
        <button class="learn-btn" style="margin-top: 15px;" [routerLink]="['/eyebrowpencil']">{{'banner.learnmore' | translate}}</button>
      </div>
    </div>
    <div class="prod-responsive">
      <div class="prod-body">
        <img style="width: 90%;padding-top: 60px;padding-left: 20px;" src="/assets/cosmetics/Eyeshadow.png" />
        <label class="product-label" style="font-weight: 600; margin-top: 120px;">Eyeshadow</label>
        <label class="product-label">"Magic Eyeshadow" that will make your heart skip a beat at first glance</label>
        <button class="learn-btn" [routerLink]="['/eyeshadow']">{{'banner.learnmore' | translate}}</button>
      </div>
    </div>
  </div>

  <div class="prod-div" *ngIf="hairpage == 2">
    <div class="prod-responsive">
      <div class="prod-body">
        <img style="width: 90%;padding-top: 50px;padding-left: 18px;" src="/assets/cosmetics/Blusher.png" />
        <label class="product-label" style="font-weight: 600; margin-top: 100px;">Blusher Series</label>
        <label class="product-label" >"Magic Blusher Series" that will keep you glowing all day</label>
        <button class="learn-btn" style="margin-top:31px;" [routerLink]="['/blusher']">{{'banner.learnmore' | translate}}</button>
      </div>
    </div>

    <div class="prod-responsive">
      <div class="prod-body">
        <img style="width: 90%;padding-top: 24px;padding-left: 18px;" src="/assets/cosmetics/Eyeliner.png" />
        <label class="product-label" style="font-weight: 600; margin-top: 75px;">Eyeliner</label>
        <label class="product-label" >"Magic Eyeliner" to enhance your eyes   </label>
        <button class="learn-btn" style="margin-top:31px;" [routerLink]="['/eyeliner']">{{'banner.learnmore' | translate}}</button>
      </div>
    </div>

    <div class="prod-responsive">
      <div class="prod-body">
        <img style="width: 90%;padding-top: 24px;padding-left: 18px;" src="/assets/cosmetics/Mascara.png" />
        <label class="product-label" style="font-weight: 600; margin-top: 40px;">Mascara Cream</label>
        <label class="product-label" >"Magic Mascara Cream" for thick and  voluminous lashes</label>
        <button class="learn-btn" style="margin-top:31px;" [routerLink]="['/mascara']">{{'banner.learnmore' | translate}}</button>
      </div>
    </div>
  </div>

  <div class="about-header" style="margin-bottom: 1%;margin-top: 0px !important;">
    <button class="hand slim-btn" (click)="showhairpage(1)"></button>
    <button class="hand slim-btn" (click)="showhairpage(2)"></button>
  </div>

  <div class="about-header" style="margin-bottom: 64px;margin-top: 0px !important;">
    <a class="hand" routerLink="/cosmeticproduct">{{'banner.viewallcosmetics' | translate}}</a>
  </div>
</div>
