<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <div style="margin-top: 10%;margin-bottom: 5%;">
        <label class="prod-link" style="margin-left: 10%;">
            <a class="prod-link" routerLink="/products">Product</a> /<a class="prod-link"
                routerLink="/skincareproduct">Skincare</a>/ Cleansing Water
        </label>
    </div>
    <div class="detail-div">
        <div class="prod-detail-grid">
            <div class="lf-half-circle">
                <button class="next-btn" style="margin-left: 11vw !important;" [routerLink]="['/toner']">PREV</button>
            </div>
            <div class="prod-detail-cols">
                <img class="prod-detail-img-toner" src="/assets/skincare/cleansingwater.png" />
            </div>
            <div class="prod-detail-title">
                <label class="prod-detail-name">MAGIC CLEANSING WATER</label><br><br>
                <label class="prod-detail-info">99% mild cleansing with just 1 cotton pad</label><br><br>
                <div style="display: flex">
                    <img style="width: 80px; height: 50px;" src="/assets/korean.png" />
                    <div
                        style="display:flex; flex-direction:column; justify-content:center; align-items: center;padding-left: 10px; font-size:14px;">
                        <label class="prod-detail-info" >Made In South Korea</label>
                    </div>
                    <div style="display:flex; flex-direction:column; justify-content:center; align-items: center;padding-left: 20px; font-size:14px">
                        <label class="prod-detail-info" >Net: 300ml</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="rg-half-circle">
            <button class="next-btn" [routerLink]="['/suncream']">NEXT</button>
        </div>
    </div>
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.productdetails' | translate}}</label>
    </div>
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;text-align: center;">
    <img *ngIf="lang == 'myn'" style="width:100%" src="/assets/018_cleansingwater/corecomponents.png" />
    <img *ngIf="lang == 'en'" style="width:90%;padding-top:7%;padding-bottom: 7%;"
        src="/assets/018_cleansingwater/corecomponents_en.png" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/018_cleansingwater/tip1.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/018_cleansingwater/tip1_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/018_cleansingwater/tip2.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/018_cleansingwater/tip2_en.png" />
</div>


<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/018_cleansingwater/tip3.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/018_cleansingwater/tip3_en.png" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/018_cleansingwater/tip4.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/018_cleansingwater/tip4_en.png" />
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF; margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%" src="/assets/018_cleansingwater/tip5.png" />
    <img *ngIf="lang == 'en'" style="width: 100%" src="/assets/018_cleansingwater/tip5_en.png" />
</div>

<style>
    .prod-detail-img-toner {
        width: 70%;
        padding-left: 20%;
        padding-top: 1%;
        margin-bottom: 50px;

    }

    @media only screen and (max-width: 600px) {
        .prod-detail-img-toner {
            width: 55%;
            padding-left: 20%;
            margin-bottom: 5%;
        }
    }
</style>