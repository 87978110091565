import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutusComponent } from './aboutus.component';
import { BBcreamComponent } from './bbcream.component';
import { CleanserComponent } from './cleanser.component';
import { CollagenComponent } from './collagen.component';
import { ConditionerComponent } from './conditioner.component';
import { ContactusComponent } from './contactus.component';
import { EmulsionComponent } from './emulsion.component';
import { EssenceComponent } from './essence.component';
import { FacialMaskComponent } from './facialmask.component';
import { GelComponent } from './gel.component';
import { HaircareProductComponent } from './haircareproduct.component';
import { HealthcareProductComponent } from './healthcateproduct.component';
import { HomeComponent } from './home.component';
import { JellyComponent } from './jelly.component';
import { LoseweightsetComponent } from './loseweightset.component';
import { MaleReplacementShakeComponent } from './malereplacementshake.component';
import { PowderComponent } from './powder.component';
import { ProductsComponent } from './products.component';
import { ShampooComponent } from './shampoo.component';
import { SkincareProductComponent } from './skincareproduct.component';
import { SleepingmaskComponent } from './sleepingmask.component';
import { SoapComponent } from './soap.component';
import { TonerComponent } from './toner.component';
import { CleansingWaterComponent } from './cleansingwater.component';
import { SuncreamComponent } from './suncream.component';
import { BodyLotionComponent } from './bodylotion.component';
import { ToothpasteComponent } from './toothpaste.component';
import { NewCleanserComponent } from './newcleanser.component';
import { NightCreamComponent } from './nightcream.component';
import { CushionComponent } from './cushion.component';
import { DayCreamComponent } from './daycream.component';
import { NewEssenceComponent } from './newessence.component';
import { PowderPactComponent } from './powderpact.component';
import { NewTonerComponent } from './newtoner.component';
import {CosmeticproductComponent} from "./cosmeticproduct.component";
import {LipstickComponent} from "./lipstick.component";
import {EyebrowpencilComponent} from "./eyebrowpencil.component";
import {EyeshadowComponent} from "./eyeshadow.component";
import {BlusherComponent} from "./blusher.component";
import {EyelinerComponent} from "./eyeliner.component";
import {MascaraComponent} from "./mascara.component";

const routes: Routes = [
  // home
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },

  //products
  { path: 'products', component: ProductsComponent },

  //about us
  { path: 'aboutus', component: AboutusComponent },

  //contact us
  { path: 'contactus', component: ContactusComponent },

  //skincareproduct
  { path: 'skincareproduct', component: SkincareProductComponent },

  //healthcareproduct
  { path: 'healthcareproduct', component: HealthcareProductComponent },

  //haircareproduct
  { path: 'haircareproduct', component: HaircareProductComponent },

  // skincare product
  //cleanser detail
  { path: 'cleanser', component: CleanserComponent },

  //bbcream detail
  { path: 'bbcream', component: BBcreamComponent },

  //sleepingmask detail
  { path: 'sleepingmask', component: SleepingmaskComponent },

  //Powder detail
  { path: 'powder', component: PowderComponent },

  //Emulsion detail
  { path: 'emulsion', component: EmulsionComponent },

  //facial mask detail
  { path: 'facialmask', component: FacialMaskComponent },

  //Essence detail
  { path: 'essence', component: EssenceComponent },

  //Toner detail
  { path: 'toner', component: TonerComponent },

  //Cleansing Water
  { path: 'cleansingwater', component: CleansingWaterComponent },

  //Sun Cream
  { path: 'suncream', component: SuncreamComponent },

  //Body Lotion
  { path: 'bodylotion', component: BodyLotionComponent },

  // Healthcare product
  //lose weight set detail
  { path: 'loseweightset', component: LoseweightsetComponent },

  //Collagen detail
  { path: 'collagen', component: CollagenComponent },

  //Jelly detail
  { path: 'jelly', component: JellyComponent },

  // Healthcare product
  //Soap detail
  { path: 'soap', component: SoapComponent },

  //Conditioner detail
  { path: 'conditioner', component: ConditionerComponent },

  //gel detail
  { path: 'gel', component: GelComponent },

  //Shampoo detail
  { path: 'shampoo', component: ShampooComponent },

  //Shampoo detail
  { path: 'malereplacement', component: MaleReplacementShakeComponent },

  //Toothpaste detail
  { path: 'toothpaste', component: ToothpasteComponent },

  //new detail
  { path: 'newcleanser', component: NewCleanserComponent },

  { path: 'nightcream', component: NightCreamComponent },

  { path: 'cushion', component: CushionComponent },

  { path: 'daycream', component: DayCreamComponent },

  { path: 'newessence', component: NewEssenceComponent },

  { path: 'powderpact', component: PowderPactComponent },

  { path: 'newtoner', component: NewTonerComponent },

  {path:"cosmeticproduct",component:CosmeticproductComponent},

  {path:"lipstick",component:LipstickComponent},

  {path:"eyebrowpencil",component:EyebrowpencilComponent},

  {path:"eyeshadow",component:EyeshadowComponent},

  {path:"blusher",component:BlusherComponent},

  {path:"eyeliner",component:EyelinerComponent},

  {path:"mascara",component:MascaraComponent},


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
