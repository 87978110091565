import { Component } from '@angular/core';
import { LocalizationService } from './localization.service';
import { ChangeDetection } from './shared/changedetection';
import { FacebookService, InitParams } from 'ngx-facebook';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'magic';
  active:number = 1;
  lang:string = 'myn';  // Default language is Myanmar

  constructor(public changed: ChangeDetection, private localizationService: LocalizationService,
    private facebookService: FacebookService ) { }
  
  ngOnInit() {
    this.initFacebookService();
    
    if(localStorage.getItem('activmenu')){
      this.active = Number(localStorage.getItem('activmenu'));
    }
    else {
      this.active = 1;
    }
  }

  private initFacebookService(): void {
    const initParams: InitParams = { xfbml:true, version:'v3.2'};
    this.facebookService.init(initParams);
  }

  onActivate() {
    window.scroll(0,0);
  }

  myFunction(data:number) {
    this.active = data;
    localStorage.setItem('activmenu', JSON.stringify(this.active));
  }

  changeLanguage(data:string){
    this.lang = data;
    this.changed.notifyEvent.emit(data);
    localStorage.setItem('language', data);
    this.localizationService.initService();
  }
  
}
