import { Component } from '@angular/core';
import { ChangeDetection } from './shared/changedetection';

@Component({
  selector: 'nightcream',
  templateUrl: './nightcream.component.html',
})
export class NightCreamComponent {
  lang: string = 'myn';

  constructor(public changed: ChangeDetection) {
    if (localStorage.getItem('language')) {
      this.lang = localStorage.getItem('language') || '';
    } else {
      this.lang = 'myn';
    }

    changed.notifyEvent.subscribe((data) => {
      this.lang = data;
      console.log(data);
    });
  }

  ngOnInit() {}
}
