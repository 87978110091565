<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <div style="margin-top: 10%;margin-bottom: 5%;">
        <label class="prod-link" style="margin-left: 10%;">
            <a class="prod-link" routerLink="/products">Product</a> /<a class="prod-link" routerLink="/skincareproduct">Skincare</a>/ Facial Mask
        </label>
    </div>
    <div class="detail-div">
        <div class="prod-detail-grid">
            <div class="lf-half-circle">
                <button class="next-btn" style="margin-left: 11vw !important;" [routerLink]="['/emulsion']">PREV</button>
            </div>
            <div class="prod-detail-cols">
                <img class="prod-detail-img-mask" src="/assets/skincare/facialmask.png" />
            </div>
            <div class="prod-detail-title">
                <label class="prod-detail-name">FACIAL MASK</label><br><br>
                <label class="prod-detail-info">Sooth Tender and Shining Facial Mask</label><br><br>
                <label class="prod-detail-info" style="font-size:14px;">Net: 25ml</label><br><br>
            </div>
        </div>
        <div class="rg-half-circle">
            <button class="next-btn" [routerLink]="['/essence']">NEXT</button>
        </div>
    </div>
</div>

<div class="section-header">
        <div class="section-title">
            <label>{{'banner.productdetails' | translate}}</label>
        </div>
</div>

<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%;" src="/assets/6_facialmaskdetail/part2.png" />
    <img *ngIf="lang == 'en'" style="width: 100%;" src="/assets/6_facialmaskdetail/part2_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%;padding-top: 5%;" src="/assets/6_facialmaskdetail/part3.png" />
    <img *ngIf="lang == 'en'" style="width: 100%;padding-top: 5%;" src="/assets/6_facialmaskdetail/part3_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%;padding-top: 5%;" src="/assets/6_facialmaskdetail/part4.png" />
    <img *ngIf="lang == 'en'" style="width: 100%;padding-top: 5%;" src="/assets/6_facialmaskdetail/part4_en.png" />
</div>

<div class="col-xs-12 overall-container" style="margin-top: 0% !important;">
    <img *ngIf="lang == 'myn'" style="width: 100%;padding-top: 5%;" src="/assets/6_facialmaskdetail/part5.png" />
    <img *ngIf="lang == 'en'" style="width: 100%;padding-top: 5%;" src="/assets/6_facialmaskdetail/part5_en.png" />
</div>

<div class="section-header">
    <div class="section-title">
        <label>{{'banner.certis' | translate}}</label>
    </div>
</div>
<div class="col-xs-12 overall-container" style="background-color: #F1F6FF;margin-top: 0% !important;">
    <img style="width: 72%;
    padding-top: 5%;
    padding-left: 15%;
    padding-bottom: 50px;" src="/assets/6_facialmaskdetail/certificate.png" />
</div>
<style>
    .prod-detail-img-mask
        {
            width:65%; 
            padding-left: 15%;
            margin-bottom: 130px;

        }
    @media only screen and (max-width: 600px) {
        .prod-detail-img-mask
        {   
            margin-top: 5%;
            width: 80%;
            padding-left: 15%;
            margin-bottom: 5px;
        }
    }
</style>