<!-- Skincare -->
<div class="col-xs-12 overall-container">
  <div style="margin-top:  2%;margin-bottom: 1%;">
    <label class="prod-link" style="margin-left: 15%;">
      <a class="prod-link" routerLink="/products">Product</a>/ Haircare
    </label>
  </div>

  <div class="about-header" style="margin-bottom: 2%;">
    <label class="title">COSMETIC PRODUCTS</label>
  </div>

  <div class="container">
    <div class="row" style="margin-bottom: 50px;">
      <div class="col-4">
        <div class="prod-body">
          <img style="width: 90%;padding-top: 24px;padding-left: 18px;" src="/assets/cosmetics/Lip-6.png" />
          <label class="product-label" style="font-weight: 700;font-size: 15px;margin-top: 40px;">Lipstick</label>
          <label class="product-label">"Magic Lipstick" that every girl love</label>
          <button style="margin-top:27px;" class="learn-btn" [routerLink]="['/lipstick']">Learn More</button>
        </div>
      </div>
      <div class="col-4">
        <div class="prod-body">
          <img style="width: 80%;padding-top: 10px;padding-left: 25px;" src="/assets/cosmetics/Eyebrow.png" />
          <label class="product-label" style="font-weight: 700;font-size: 15px;margin-top: 26px;">Eyebrow Pencil</label>
          <label class="product-label">"Doubled Headed Eyebrow Pencil" for perfectly groomed eyebrows</label>
          <button class="learn-btn" style="margin-top:10px;" [routerLink]="['/eyebrowpencil']">Learn More</button>
        </div>
      </div>
      <div class="col-4">
        <div class="prod-body">
          <img style="width: 90%;padding-top: 60px;padding-left: 20px;" src="/assets/cosmetics/Eyeshadow.png" />
          <label class="product-label" style="font-weight: 700;font-size: 15px;margin-top: 110px;">Eyeshadow</label>
          <label class="product-label">"Magic Eyeshadow" that will make your heart skip a beat at first glance</label>
          <button class="learn-btn" [routerLink]="['/eyeshadow']">Learn More</button>
        </div>
      </div>
      <div class="col-4">
        <div class="prod-body">
          <img style="width: 90%;padding-top: 50px;padding-left: 18px;" src="/assets/cosmetics/Blusher.png" />
          <label class="product-label" style="font-weight: 700;font-size: 15px;margin-top: 100px;">Blusher Series</label>
          <label class="product-label">"Magic Blusher Series" that will keep you glowing all day</label>
          <button class="learn-btn" style="margin-top:31px;" [routerLink]="['/blusher']">Learn More</button>
        </div>
      </div>

      <div class="col-4">
        <div class="prod-body">
          <img style="width: 90%;padding-top: 24px;padding-left: 18px;" src="/assets/cosmetics/Eyeliner.png" />
          <label class="product-label" style="font-weight: 700;font-size: 15px;margin-top: 50px;">Eyeliner</label>
          <label class="product-label">"Magic Eyeliner" to enhance your eyes</label>
          <button class="learn-btn" style="margin-top:55px;" [routerLink]="['/eyeliner']">Learn More</button>
        </div>
      </div>

      <div class="col-4">
        <div class="prod-body">
          <img style="width: 90%;padding-top: 24px;padding-left: 18px;" src="/assets/cosmetics/Mascara.png" />
          <label class="product-label" style="font-weight: 700;font-size: 15px;margin-top: 40px;">Mascara</label>
          <label class="product-label">"Magic Mascara Cream" for thick and  voluminous lashes</label>
          <button class="learn-btn" style="margin-top:31px;" [routerLink]="['/mascara']">Learn More</button>
        </div>
      </div>
    </div>
  </div>

</div>
