<div class="col-xs-12 overall-container">
  <div class="cate-div">
    <div class="prod-cate">
      <div class="contact-cate">
        <img
          class="contact-img"
          style="width: 25%"
          src="/assets/contact/address.png"
        />
        <div class="contact-info">
          <p>
            အမှတ် မ၄၂/၅က၊ မြို့ပတ်လမ်းနှင့် ၅၂ လမ်းကြား၊ ရဲမွန်တောင်ရပ်ကွက်၊
            <br />
            မဟာအောင်မြေမြို့နယ်၊ မန္တလေးမြို့။
          </p>
        </div>
      </div>
    </div>
    <div class="prod-cate">
      <div class="contact-cate">
        <img class="contact-img" src="/assets/contact/business talk.png" />
        <div class="contact-info" style="margin-top: 40px">
          <a href="tel:+959-761-888-999"><b>+959-761-888-999</b></a>
        </div>
      </div>
    </div>
    <div class="prod-cate">
      <div class="contact-cate">
        <img class="contact-img" src="/assets/contact/social.png" />
        <div class="contact-info">
          <a href="https://www.facebook.com/magicmmofficial/">
            <img src="/assets/contact/facebook.png" class="iconDetails"
          /></a>
        </div>
      </div>
    </div>
  </div>

  <!-- Contact Us -->
  <div class="section-header">
    <div class="section-title">
      <label>{{ "banner.contactus" | translate }}</label>
    </div>
  </div>

  <div
    class="container"
    style="margin: auto; display: grid; margin-bottom: 64px"
  >
    <div class="row">
      <div class="contact-label">
        <label class="contact-title">{{
          "banner.haveaprivatequestion" | translate
        }}</label>
        <div>
          <button class="contact-btn">
            <a
              href="tel:+959-761-888-999"
              style="text-decoration: none; color: #fff"
              >{{ "banner.contactus" | translate }}</a
            >
          </button>
        </div>
      </div>

      <div class="contact-form">
        <div class="contact-header-div">
          <label class="contact-header">LEAVE A MESSAGE</label><br />
        </div>

        <label style="margin-top: 25px; font-family: nunito">Name</label><br />
        <input name="name" class="contact-input" /><br />

        <label style="font-family: nunito">Email</label><br />
        <input name="email" class="contact-input" /><br />

        <label style="font-family: nunito">Phone Number</label><br />
        <input name="phone" class="contact-input" /><br />

        <label style="font-family: nunito">Message</label><br />
        <textarea name="message" class="contact-textarea"></textarea>

        <button style="font-family: nunito" class="send-btn">Send</button>
      </div>
    </div>
  </div>
</div>

<style>
  .iconDetails {
    width: 50%;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
  }

  h4 {
    margin: 0px;
  }

  a,
  a:visited,
  a:hover {
    color: #ffff;
    text-decoration: none;
  }
</style>
