import { Component } from '@angular/core';
import { ChangeDetection } from './shared/changedetection';

@Component({
  selector: 'jelly',
  templateUrl: './jelly.component.html',
})
export class JellyComponent {
  lang:string = 'myn';

  constructor(public changed: ChangeDetection) { 
    if(localStorage.getItem('language')){
      this.lang = localStorage.getItem('language') || '';
    }
    else {
      this.lang = 'myn';
    }

    changed.notifyEvent.subscribe( data => { this.lang = data; console.log(data) });
  }

  ngOnInit() {
    
  }
}
